import styled from 'styled-components'
import { Star, EmptyStar } from '@ig-caa/media'

export default function StarsRating ({ rating }: { rating: number }): JSX.Element {
  const star = (filling: number, index: number): JSX.Element => (
    <StarContainer key={index}>
      <StarShadow>
        <EmptyStar/>
      </StarShadow>
      <StarFill data-testid="star" filling={ filling }>
        <Star/>
      </StarFill>
    </StarContainer>
  )

  const stars = []
  for (let i = 0; i < 5; i++) {
    const diff = rating - i
    const starRating = diff >= 1 ? 1 : (diff > 0 ? diff : 0)
    stars.push(star(starRating, i))
  }

  return (
    <StarsRatingContainer>
      { stars }
    </StarsRatingContainer>
  )
}

const StarsRatingContainer = styled.div`
  display: flex;
  height: 12px;
`

const StarContainer = styled.div`
  position: relative;
  margin-right: 2px;
  width: 12px;
`

const StarFill = styled.div<{
  filling: number
}>`
  width: ${({ filling }) => (filling * 12).toFixed(1)}px;
  overflow: hidden;
  position: absolute;
  display: inline-grid;
`

const StarShadow = styled.div`
  position: absolute;
  top: 0;
  display: flex;
`

export function roundBigNumbers (num: number | null): string | number {
  if (num === null) {
    return '—'
  }
  if (Math.abs(num) >= 1000000000) {
    return (Math.abs(num) / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B'
  }
  if (Math.abs(num) >= 1000000) {
    return (Math.abs(num) / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  }
  if (Math.abs(num) >= 1000) {
    return (Math.abs(num) / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  }
  return Math.abs(num)
}

export function percent (value: number | null, precision: number): string {
  if (value === null) {
    return '—'
  }

  const minValue = Math.pow(10, -precision)

  if (value !== 0 && Math.abs(value * 100) < minValue) {
    return value > 0 ? `<${minValue}%` : `>${-minValue}%`
  }
  return (value * 100).toFixed(precision) + '%'
}

export function money (locale: string, currency: string, value: number | null, precision: number): string {
  if (value === null) {
    return '—'
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    notation: 'compact'
  }).format(Math.abs(+value))
}

import '../../../styles/ACMiniView.css'
import styled from 'styled-components'
import { NoAnalystConsensus } from '@ig-caa/media'
import { ErrorProps } from '../../../utils/getErrorType'

export default function MiniErrorPage ({ pageType }: ErrorProps): JSX.Element {
  if (pageType === 'EMPTY_DATA') {
    return (
      <MiniErrorContainer data-testid='no-data-error-card'>
        <div className='analyst-consensus_mini-error-container'>
          <div className='analyst-consensus_mini-error-icon'><NoAnalystConsensus /></div>
          <div>No Analyst Consensus available yet</div>
        </div>
      </MiniErrorContainer>
    )
  } else if (pageType === 'NO_TIPRANKS') {
    return (
      <MiniErrorContainer data-testid='no-tipranks-error-card'>
        <div>There’s no TipRanks data for this market.</div>
      </MiniErrorContainer>
    )
  } else {
    return (
      <MiniErrorContainer data-testid='loading-error-card'>
        {'We’re not able to load this data right now'} <br/> {'Please come back later'}
      </MiniErrorContainer>
    )
  }
}

const MiniErrorContainer = styled.div`
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  border-radius: 8px;
  min-width: 200px;
  min-height: 56px;
  max-height: 64px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  line-height: 16px;
  text-align: center;
  font-weight: 400;
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  justify-content: center;
`

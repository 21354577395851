import React from 'react'
import styled from 'styled-components'

export default function Scale (
  score: number,
  mini: boolean,
  colShort: string,
  colLong: string,
  colPrimary: string,
  colTertiary: string,
  indicatorColor: string
): JSX.Element[] {
  const scale = Array.from(Array(10).keys()).map((num, i) =>
    <ScaleSection key={num}>
      <ScaleNumber
        className='scale-number'
        fontWeight={num === score - 1 ? 'bold' : ''}
        color={num === score - 1 ? colPrimary : colTertiary}
      >
        {num + 1}
      </ScaleNumber>
      <ScaleBar
        color={num + 1 <= 5 ? colShort : colLong}
        style={{ opacity: num + 1 <= 5 ? (1 - (0.2 * i)) : ((0.2 * i) - 0.8) }}
      />
    </ScaleSection>
  )

  return scale
}

const ScaleSection = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 62px;
  min-width: 16px;
`

const ScaleNumber = styled.div<{
  fontWeight: string
  color: string
}>`
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  text-align: center;
  max-width: 62px;
`

const ScaleBar = styled.div`
  background: ${props => props.color};
  height: 8px;
  max-width: 62px;
  min-width: 16px;
`

import styled from 'styled-components'

export const Title = styled.h5`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin: 0;
`

export const H1 = styled.span`
  font-size: ${({ theme }) => theme.size.heading.h1.text.value};
  line-height: ${({ theme }) => theme.size.heading.h1.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
`

export function gbx2DpCurrencyFormat (locale: string, currencyCode: string, price: number) {
  return currencyCode === ''
    ? '-'
    : currencyCode === 'GBX'
      ? price < 10000
        ? price.toFixed(2) + 'p'
        : price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'p'
      : Intl.NumberFormat(
        locale, { style: 'currency', currency: currencyCode, maximumFractionDigits: 2 }
      ).format(price)
};

import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Informative, Tooltip } from 'ig-phoenix'
import { circleBgColor, circleTextColor } from './score-display/Helpers'
import { Circle } from './score-display/Circle'
import { LIGHT_THEME } from './AppProps'

export default function PerformanceTooltip (): JSX.Element {
  const theme = useTheme()

  const colShort = theme.color.text.short.value
  const colLong = theme.color.text.long.value
  const colPrimary = theme.color.text.primary.value
  const colPrimaryInverted = theme.color.text.inverted.value
  /* istanbul ignore next */
  const textCol = (num: number) => circleTextColor(num, colPrimary, theme.name === LIGHT_THEME ? colPrimaryInverted : colPrimary)

  const scoreCircles = (array: number[]): JSX.Element => (
    <div className='performance-tooltip-score'>
      {array.map((num) => (
        <Circle
          key={num}
          bgColor={circleBgColor(num, colShort, colLong)}
          textColor={textCol(num)}
          score={num}
          classname='tooltip-circle'
          data-testid={`score-circle-${num}`}/>
      ))}
    </div>
  )

  const tooltipBody = (
    <div data-testid='performanceTooltip' className='performance-tooltip-body'>
      Smart Score<br/>
      This stock rating system ranges from highest score 10 to lowest score 1.<br/><br/>
      Stocks with a score of 8–10 are considered ‘outperform’{ scoreCircles([8, 9, 10])}<br/>
      Stocks with a score of 4–7 are considered ‘neutral’{ scoreCircles([4, 5, 6, 7])}<br/>
      Stocks with a score of 1–3 are considered ‘underperform’{ scoreCircles([1, 2, 3])}
    </div>
  )

  return (
    <span className='tooltip-container'>
      <Tooltip
        position='bottom'
        zIndex='9'
        tipPosition='-175px'
        top='25px'
        width='340px'
        tooltipRender={() => tooltipBody}
      >
        <IconDiv data-testid='info-icon'><Informative/></IconDiv>
      </Tooltip>
    </span>
  )
}

const IconDiv = styled.div`
  vertical-align: bottom;

  svg {
    width: 16px;
    height: 16px;
    margin: 2px 2px 0;
    display: inline-block;
  }
`

import { ENVIRONMENTS, AnalystProfileData } from '../components/analyst-consensus/AppProps'

const ENDPOINTS = (expertUID: string) => ({
  prod: `https://tipranks-api.ig.com/tipranks/api/analyst-profile/${expertUID}`,
  test: `https://net-tipranks-api.ig.com/tipranks/api/analyst-profile/${expertUID}`,
  uat: `https://web-tipranks-api.ig.com/tipranks/api/analyst-profile/${expertUID}`,
  demo: `https://demo-tipranks-api.ig.com/tipranks/api/analyst-profile/${expertUID}`,
  local: `https://net-tipranks-api.ig.com/tipranks/api/analyst-profile/${expertUID}`
})

export default async function fetchAnalystProfile (env: keyof typeof ENVIRONMENTS, expertUID: string, cst: string): Promise<AnalystProfileData> {
  if (expertUID === '') {
    throw new Error('missing expertUID')
  }
  try {
    const response: Response = await fetch(`${ENDPOINTS(expertUID)[env]}`, {
      headers: {
        cst
      }
    })
    if (!response.ok) {
      throw new Error(`${response.status}`)
    }

    const responseData: AnalystProfileData = await response.json()

    return responseData
  } catch (error) {
    throw new Error('Fetch error')
  }
}

import React from 'react'
import styled from 'styled-components'
import { NoSmartScore } from '@ig-caa/media'
import { ErrorProps } from '../../utils/getErrorType'

export default function ErrorPage ({ pageType }: ErrorProps): JSX.Element {
  if (pageType === 'NO_TIPRANKS') {
    return (
      <Page data-testid='error-no-tipranks'>
        <ErrorText>There’s no TipRanks data for this market.</ErrorText>
      </Page>
    )
  } else if (pageType === 'EMPTY_DATA') {
    return (
      <Page data-testid='error-no-score'>
        <Icon><NoSmartScore /></Icon>
        <Message>No score available yet</Message>
      </Page>
    )
  } else {
    return (
      <Page data-testid='error-loading'>
        <ErrorText>{'We’re not able to load this data right now'} <br/> {'Please come back later'}</ErrorText>
      </Page>
    )
  }
}

const Page = styled.div`
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-width: 200px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.text.secondary.value};
`
export const Icon = styled.div`
  height: 24px;
  width: 48px;
`
export const Message = styled.div`
  margin-left: 16px;
  font-size: 13px;
  line-height: 24px;
`
export const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  text-align: center;
  line-height: 16px;
`

import styled from 'styled-components'
import '../../../styles/AnalystConsensus.css'
import { TRACKING_ACTIONS, TrackInteraction } from '../AppProps'

interface ArticleProps {
  analystName: string
  articleTitle: string
  articleQuote: string
  articleSite: string
  url: string
  timestamp: Date
  profilePicture: JSX.Element
  epic: string
  instrumentName: string
  trackInteraction: TrackInteraction
}

export default function ArticlePopup (props: ArticleProps): JSX.Element {
  const { analystName, articleTitle, articleQuote, articleSite, url, timestamp, profilePicture, epic, instrumentName, trackInteraction } = props

  const today = new Date()
  const timestampAsDate = new Date(timestamp)

  const dateOrTime = (today: Date, timestamp: Date): string => {
    if (today.toDateString() === timestamp.toDateString()) {
      return timestamp.toLocaleTimeString('en-GB').slice(0, 5)
    }
    return timestamp.toDateString().slice(4, 15)
  }

  const publishDate = dateOrTime(today, timestampAsDate)

  if (articleSite === '' || url === '') {
    return (
      <ArticlePopupCard data-testid='article-error-card' style={{ alignItems: 'center' }}>
        <ErrorText>
          {'We’re not able to load this data right now'} <br/> {'Please come back later'}
        </ErrorText>
      </ArticlePopupCard>
    )
  } else {
    return (
      <ArticlePopupCard data-testid='article-popup-card'>
        <Title>{articleTitle}</Title>
        <div className='profile-container'>
          {profilePicture}
          <div className='name-date-container'>
            <AnalystName>{analystName}</AnalystName>
            <PublishDate>{publishDate}</PublishDate>
          </div>
        </div>
        <Quote data-testid='article-quote'>{articleQuote}</Quote>
        <ArticleLink
          className='article-link'
          data-testid='article-link'
          href={url}
          target='_blank'
          onClick={function () {
            trackInteraction(TRACKING_ACTIONS.OPEN_ARTICLE, articleTitle, epic, instrumentName)
          }}
        >
          {articleSite}
        </ArticleLink>
      </ArticlePopupCard>
    )
  }
}

const Title = styled.div`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
  font-size: ${({ theme }) => theme.size.body.medium.text.value};
  line-height: ${({ theme }) => theme.size.body.medium.lineHeight.value};
`

const AnalystName = styled.div`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-weight: ${({ theme }) => theme.font.heading.h4.weight.value};
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.heading.h6.lineHeight.value};
`

const PublishDate = styled.div`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-weight: ${({ theme }) => theme.font.caption.weight.value};
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
`

const Quote = styled.div`
  font-weight: ${({ theme }) => theme.font.caption.weight.value};
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  color: ${({ theme }) => theme.color.text.primary.value};
`

const ArticleLink = styled.a`
  font-weight: ${({ theme }) => theme.font.caption.weight.value};
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  color: ${({ theme }) => theme.color.text.link.value};
  margin-left: auto;
`

const ArticlePopupCard = styled.div`
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  border-radius: 8px;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(38, 38, 41, 0.16);
  gap: 16px;
`

const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  text-align: center;
`

import { useTheme } from 'styled-components'
import React from 'react'

export default function ArrowDown (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.text.negativeAmount.value
  return (
    <svg width="10" height="9" viewBox="-1 1 9 9" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.35036 6.14411C6.39822 6.32166 6.34317 6.50765 6.21376 6.63074L3.53025 9.24726C3.43658 9.33866 3.3117 9.38818 3.18359 9.38818C3.05549 9.38818 2.93061 9.33866 2.83694 9.24726L0.153392 6.6307C0.0241449 6.50756 -0.0307467 6.32165 0.0171369 6.14422C0.0647514 5.96779 0.203557 5.83721 0.372265 5.7939C0.540229 5.75079 0.721153 5.79795 0.846576 5.92292L2.69784 7.72795L2.69784 0.735192C2.69784 0.451497 2.91547 0.221517 3.18392 0.221517C3.45237 0.221517 3.66999 0.451498 3.66999 0.735192L3.66999 7.72729L5.52052 5.92298C5.6459 5.79781 5.82692 5.7505 5.995 5.79358C6.16384 5.83685 6.30277 5.96753 6.35036 6.14411Z"/>
    </svg>
  )
}

import { CONSENSUS, StockAnalystData, StockOverviewData } from '../components/analyst-consensus/AppProps'
import { SmartScoreData } from '../components/smart-score/AppProps'
import { ERROR_TYPE } from './getErrorType'

export const validateAnalystConsensusData = (data: StockOverviewData): StockOverviewData => {
  if (!data?.ticker) {
    throw new Error(ERROR_TYPE.EMPTY_DATA)
  }

  const clone: Partial<StockOverviewData> = { ...data }
  delete clone.ticker // these always have a value returned
  delete clone.priceTargetCurrencyCode

  const dataValues = Object.values(clone)

  const badValues = ['-', 'N/A', 'NAN%', 'NAN']
  /* istanbul ignore if */
  if (!dataValues.some((value) => value !== null && value !== undefined && !badValues.includes(`${value}`))) {
    throw new Error(ERROR_TYPE.EMPTY_DATA)
  }

  return data
}

export const validateAnalystConsensusMiniData = (data: StockOverviewData): StockOverviewData => {
  if (!data?.consensus || data.consensus === CONSENSUS.NA) {
    throw new Error(ERROR_TYPE.EMPTY_DATA)
  }

  return data
}

export const validateRes = (response: Response): Response => {
  if (!response.ok) {
    throw new Error(`${response.status}`)
  }
  return response
}

export const getData = async (res: Response): Promise<StockOverviewData> => {
  try {
    const responseData = await res.json()
    return responseData[0]
  } catch (e) {
    throw new Error('PARSE_ERROR')
  }
}

export const validateStockAnalystData = (data: any): StockAnalystData[] => {
  try {
    if (!Array.isArray(data) || data.length <= 0 || data.some((item: any) => item.ticket === '')) {
      throw new Error(ERROR_TYPE.EMPTY_DATA)
    }
    return data as StockAnalystData[]
  } catch (e) {
    throw new Error('PARSE_ERROR')
  }
}

export const isKnownError = (error: unknown): error is Error => typeof error === 'object' && error !== null && 'message' in error

export const validateSmartScoreData = (data: SmartScoreData): SmartScoreData => {
  if (!data?.ticker) {
    throw new Error(ERROR_TYPE.EMPTY_DATA)
  }

  const clone: Partial<SmartScoreData> = { ...data }
  delete clone.ticker // these always have a value returned
  delete clone.priceTargetCurrencyCode

  const dataValues = Object.values(clone)
  // if any of the values are actual values, we consider this valid to display Smart Score
  const badValues = ['-', 'N/A', 'NAN%', 'NAN']
  if (!dataValues.some((value) => value !== null && value !== undefined && !badValues.includes(`${value}`))) {
    throw new Error(ERROR_TYPE.EMPTY_DATA)
  }

  return data
}

export interface MiniViewSmartScoreData extends SmartScoreData {
  smartScore: number
}

export const validateSmartScoreMiniData = (data: SmartScoreData): MiniViewSmartScoreData => {
  if (!data?.smartScore || data.smartScore < 1 || data.smartScore > 10) {
    throw new Error(ERROR_TYPE.EMPTY_DATA)
  }

  return data as MiniViewSmartScoreData
}

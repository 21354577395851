import { ENVIRONMENTS, StockAnalystData } from '../components/analyst-consensus/AppProps'
import { isKnownError, validateRes, validateStockAnalystData } from './validationHelpers'
import { ERROR_TYPE } from './getErrorType'

const ENDPOINTS = (marketId: string) => ({
  prod: `https://tipranks-api.ig.com/tipranks/api/analysts/${marketId}`,
  test: `https://net-tipranks-api.ig.com/tipranks/api/analysts/${marketId}`,
  uat: `https://web-tipranks-api.ig.com/tipranks/api/analysts/${marketId}`,
  demo: `https://demo-tipranks-api.ig.com/tipranks/api/analysts/${marketId}`,
  local: `https://net-tipranks-api.ig.com/tipranks/api/analysts/${marketId}`
})

export default async function fetchStockAnalysts (env: keyof typeof ENVIRONMENTS, marketId: string, cst: string): Promise<StockAnalystData[]> {
  if (marketId === '') {
    throw new Error('missing marketId')
  }
  try {
    const response: Response = await fetch(`${ENDPOINTS(marketId)[env]}`, {
      headers: {
        cst
      }
    }).then(validateRes)

    const responseData: StockAnalystData[] = await response.json().then(validateStockAnalystData)
    return responseData
  } catch (error) {
    /* istanbul ignore next */
    if (!isKnownError(error)) {
      throw new Error(ERROR_TYPE.DEFAULT)
    }
    switch (error.message) {
      case '404':
        throw new Error(ERROR_TYPE.NO_TIPRANKS)
      case 'PARSE_ERROR':
        throw new Error(ERROR_TYPE.EMPTY_DATA)
      default:
        throw new Error(ERROR_TYPE.DEFAULT)
    }
  }
}

import styled from 'styled-components'
import { StockOverviewData } from '../AppProps'
import { gbx2DpCurrencyFormat } from '../helpers'

export default function RatingsDescription ({ stockData, clientLocale }: { stockData: StockOverviewData, clientLocale: string }): JSX.Element {
  return (
    <BodyText data-testid='ratings-description'>
      Based on <b>{stockData.totalAnalysts}</b>{' '}
      Wall Street analysts offering 12 month price targets for <b>{stockData.companyName}</b> in the last 3 months.
      The average price target is <b>{gbx2DpCurrencyFormat(clientLocale, stockData.priceTargetCurrencyCode, stockData.priceTarget)}</b>{' '}
      with a high forecast of <b>{gbx2DpCurrencyFormat(clientLocale, stockData.priceTargetCurrencyCode, stockData.highPriceTarget)}</b>{' '}
      and a low forecast of <b>{gbx2DpCurrencyFormat(clientLocale, stockData.priceTargetCurrencyCode, stockData.lowPriceTarget)}</b>.
      The average price target represents a <b>{stockData.priceTargetUpside.toFixed(2)}%</b>{' '}
      change from the last price of <b>{gbx2DpCurrencyFormat(clientLocale, stockData.priceTargetCurrencyCode, stockData.lastPrice)}</b>.
    </BodyText>
  )
}

const BodyText = styled.p`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  font-weight: 400;
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  margin: 0;
`

import { StockRatingCardProps } from './AppProps'
import styled from 'styled-components'
import { Spinner } from 'ig-phoenix'
import { CardHeader, LoadingSpinner } from './AnalystConsensus'
import ErrorPage, { ErrorText } from './ErrorPage'
import { ERROR_TYPE } from '../../utils/getErrorType'
import StockRating from './stock-rating/StockRating'
import RatingsDescription from './stock-rating/RatingsDescription'

/**
 * Stock Rating Card
 * Component showing the stock rating at the top of the Analyst Consensus full view
 */
export default function StockRatingCard ({ overviewData, overviewLoading, overviewError, isOverviewError, clientLocale }: StockRatingCardProps): JSX.Element {
  const stockOverviewNoData = overviewError?.message === ERROR_TYPE.EMPTY_DATA
  const stockOverviewError = isOverviewError && overviewError?.message !== ERROR_TYPE.EMPTY_DATA

  return (
    <div>
      <CardHeader>STOCK ANALYST RATINGS AND 12 MONTHS FORECAST</CardHeader>
      <StockRatingsCard className='stock-ratings-card' data-testid='stock-ratings-card'>
        { stockOverviewError &&
        <ErrorText data-testid='stock-overview-error-card'>
          Due to an error, this information is currently unavailable.<br/>Please try again later.
        </ErrorText>
        }

        { stockOverviewNoData &&
        <div data-testid='stock-overview-no-data'><ErrorPage pageType={ERROR_TYPE.EMPTY_DATA} /></div>
        }

        {overviewLoading &&
        <LoadingSpinner data-testid='analyst-overview-loading'>
          <Spinner radius='40px'/>
        </LoadingSpinner>}
        {overviewData && <StockRating stockData={overviewData} miniView={false} clientLocale={clientLocale}/>}
        {overviewData && <RatingsDescription stockData={overviewData} clientLocale={clientLocale}/>}
      </StockRatingsCard>
    </div>
  )
}

const StockRatingsCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-width: 260px;
  max-width: 800px;
  width: 100%;
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  border: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  border-radius: 8px;
  margin-top: 8px;
  gap: 16px;
`

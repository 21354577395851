import { useTheme } from 'styled-components'
import React from 'react'

export default function NoProfilePicture ({size}: {size?: string}): JSX.Element {
  const theme = useTheme()
  const backgroundColour = theme.color.background.primary.value
  const borderColour = theme.color.separator.primary.background.value
  const person = theme.color.toggle.disabled.background.value

  if (size === 'small') {
    return (
      <svg width="32" height="33" viewBox="0 0 32 33" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16.8882" r="15.5" transform="rotate(90 16 16.8882)" fill={backgroundColour} stroke={borderColour} />
        <mask id="mask0_1722_22271" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="33">
          <circle cx="16" cy="16.8882" r="16" transform="rotate(90 16 16.8882)" />
        </mask>
        <g mask="url(#mask0_1722_22271)">
          <path fill={person} fillRule="evenodd" clipRule="evenodd" d="M7.37673 26.9934C11.0695 25.7776 11.958 24.5833 12.0072 21.3156C11.6427 21.0277 11.3124 20.6991 11.0225 20.3362C10.2333 19.2394 9.75069 17.9522 9.62435 16.6069C9.56755 16.6216 9.50914 16.6291 9.45047 16.6293C8.77266 16.6293 8.25954 14.9657 8.25954 13.7957C8.25954 12.6256 8.60785 12.27 8.9401 12.27C9.02793 12.2701 9.11564 12.2764 9.2026 12.2887C9.0042 11.4611 8.89753 10.6141 8.88454 9.76317C8.88454 5.38718 10.5513 2.88818 15.7595 2.88818C18.2405 2.88818 18.4076 3.54006 18.8845 4.45068C19.1504 4.23927 19.4824 4.12859 19.822 4.13818C21.1466 4.13818 22.6345 5.83818 22.6345 9.76317C22.6215 10.6142 22.5147 11.4611 22.3163 12.2887C22.4032 12.2764 22.4909 12.2701 22.5788 12.27C22.911 12.27 23.2591 12.6258 23.2591 13.7957C23.2591 14.9655 22.7462 16.6293 22.0684 16.6293C22.0097 16.6291 21.9513 16.6216 21.8945 16.6069C21.7682 17.9522 21.2856 19.2394 20.4964 20.3362C20.2064 20.6991 19.876 21.0277 19.5115 21.3156C19.5607 24.5833 20.449 25.7776 24.1419 26.9934C27.5967 28.1309 29.2265 28.9253 29.5141 31.8502C29.5271 31.9813 29.5125 32.1136 29.4713 32.2388C29.4301 32.3639 29.3632 32.479 29.2749 32.5768C29.1866 32.6745 29.0788 32.7527 28.9585 32.8064C28.8381 32.86 28.7079 32.8879 28.5762 32.8882H2.94249C2.81074 32.8879 2.68053 32.86 2.56019 32.8064C2.43986 32.7527 2.33207 32.6745 2.24376 32.5768C2.15544 32.479 2.08854 32.3639 2.04735 32.2387C2.00617 32.1136 1.99161 31.9812 2.00461 31.8501C2.29218 28.9251 3.9218 28.1308 7.37673 26.9934Z"/>
        </g>
      </svg>
    )
  } else {
    return (
      <svg width="80" height="82.5" viewBox="0 0 80 82.5" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="42.2" r="39" transform="rotate(90 40 42.2)" fill={backgroundColour} stroke={borderColour} />
          <mask id="mask0_1719_19262" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="82.5">
        <circle cx="40" cy="42.2" r="40" transform="rotate(90 40 42.2)" />
        </mask>
        <g mask="url(#mask0_1719_19262)">
          <path fill={person} fillRule="evenodd" clipRule="evenodd" d="M18.442 67.484C27.674 64.444 29.895 61.458 30.018 53.289 29.107 52.569 28.281 51.748 27.556 50.841 25.583 48.099 24.377 44.881 24.061 41.517 23.919 41.554 23.773 41.573 23.626 41.573 21.932 41.573 20.649 37.414 20.649 34.489 20.649 31.564 21.52 30.675 22.35 30.675 22.57 30.675 22.789 30.691 23.007 30.722 22.511 28.653 22.244 26.535 22.211 24.408 22.211 13.468 26.378 7.22 39.399 7.22 45.601 7.22 46.019 8.85 47.211 11.127 47.876 10.598 48.706 10.321 49.555 10.345 52.867 10.345 56.586 14.595 56.586 24.408 56.554 26.536 56.287 28.653 55.791 30.722 56.008 30.691 56.227 30.675 56.447 30.675 57.278 30.675 58.148 31.565 58.148 34.489 58.148 37.414 56.866 41.573 55.171 41.573 55.024 41.573 54.878 41.554 54.736 41.517 54.421 44.881 53.214 48.099 51.241 50.841 50.516 51.748 49.69 52.569 48.779 53.289 48.902 61.458 51.123 64.444 60.355 67.484 68.992 70.327 73.066 72.313 73.785 79.626 73.818 79.953 73.781 80.284 73.678 80.597 73.575 80.91 73.408 81.197 73.187 81.442 72.967 81.686 72.697 81.882 72.396 82.016 72.095 82.15 71.77 82.22 71.441 82.22H7.356C7.027 82.22 6.701 82.15 6.4 82.016 6.1 81.882 5.83 81.686 5.609 81.442 5.389 81.197 5.221 80.91 5.118 80.597 5.015 80.284 4.979 79.953 5.012 79.625 5.73 72.313 9.805 70.327 18.442 67.484Z" />
        </g>
      </svg>
    )
  }
}
import '../../../styles/SSMiniView.css'
import React from 'react'
import { MiniViewProps, TRACKING_ACTIONS } from '../AppProps'
import styled from 'styled-components'
import MiniErrorPage from './MiniErrorPage'
import MiniScoreDisplay from './MiniScoreDisplay'
import { useQuery } from '@tanstack/react-query'
import fetchSmartScore from '../../../utils/fetchSmartScore'
import { Spinner } from 'ig-phoenix'
import { MiniViewSmartScoreData, validateSmartScoreMiniData } from '../../../utils/validationHelpers'
import { getErrorType } from '../../../utils/getErrorType'

export default function MiniView (props: MiniViewProps): JSX.Element {
  const { toggleMiniView, marketId, env, cst, epic, instrumentName, trackInteraction } = props

  const { isLoading, isError, error, data } = useQuery<MiniViewSmartScoreData, any>({
    queryKey: ['smartscore', marketId],
    queryFn: async () => await fetchSmartScore(env, marketId, cst).then(validateSmartScoreMiniData),
    refetchOnWindowFocus: false
  })

  if (isLoading) {
    return <div className="smart-score-loading" data-testid="isLoading"><Spinner radius='40px'/></div>
  }

  if (isError) {
    return <MiniErrorPage pageType={getErrorType(error?.message)} />
  }

  return (
    <div className='mini-view' data-testid='smart-score_mini-view'>
      <Card data-testid='mini-view-card'>
        <MiniScoreDisplay score={data.smartScore}/>
        <Summary className='smart-score-summary' data-testid='mini-summary'>
          {'See a stock’s rating at a glance, clearly pitched on the Smart Score scale. Powered by TipRanks, the score combines 8 unique data sets to generate a score between 1-10.'}
        </Summary>
        <MoreLinkElement
          className='view-more-link'
          data-testid='smart-score-mini-more-link'
          onClick={function () {
            toggleMiniView()
            trackInteraction(TRACKING_ACTIONS.SMART_SCORE_VIEW_MORE, 'View more', epic, instrumentName)
          }}
        >
          View more
        </MoreLinkElement>
      </Card>
    </div>
  )
}

const Summary = styled.div`
  font-weight: 400;
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  line-height: 16px;
`

const MoreLinkElement = styled.a`
  font-weight: 400;
  font-size: 11px;
  line-height: ${({ theme }) => theme.size.link.medium.lineHeight.value};
  color: ${({ theme }) => theme.color.text.link.value};
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
`

const Card = styled.div`
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  border-radius: 8px;
  min-width: 200px;
  max-width: 392px;
  height: 208px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  box-sizing: border-box;
`

import styled from 'styled-components'
import { ENVIRONMENTS, StockAnalystData, TrackInteraction } from '../AppProps'
import StarsRating from '../analyst-profile/StarsRating'
import { ArticleIcon, NoProfilePicture } from '@ig-caa/media'
import Heading from './Heading'
import { useState } from 'react'
import ArticlePopup from './ArticlePopup'
import AnalystProfile from '../analyst-profile/AnalystProfile'
import TetherComponent from 'react-tether'
import HoverableCard from './HoverableCard'
import { gbx2DpCurrencyFormat } from '../helpers'

interface AnalystForecastTableProps {
  analystsData: StockAnalystData[]
  lastPrice?: number
  env: ENVIRONMENTS
  cst: string
  clientLocale: string
  epic: string
  instrumentName: string
  trackInteraction: TrackInteraction
}

interface AnalystForecastRowProps {
  analystData: StockAnalystData
  lastPrice?: number
  env: ENVIRONMENTS
  cst: string
  clientLocale: string
  epic: string
  instrumentName: string
  trackInteraction: TrackInteraction
}

export default function AnalystForecastTable (props: AnalystForecastTableProps): JSX.Element {
  const { env, analystsData, lastPrice, cst, clientLocale, epic, instrumentName, trackInteraction } = props
  const listOfAnalysts = analystsData.map((analyst) => {
    return <AnalystDataRow key={analyst.expertUID} analystData={analyst} lastPrice={lastPrice} env={env} cst={cst} clientLocale={clientLocale} epic={epic}
      instrumentName={instrumentName} trackInteraction={trackInteraction} />
  })
  return (
    <table data-testid='analyst-forecast-table' className='analyst-forecast-table'>
      <thead>
        <Heading />
      </thead>
      <AnalystForecastTBody>
        {listOfAnalysts}
      </AnalystForecastTBody>
    </table>
  )
}

const AnalystDataRow = (props: AnalystForecastRowProps): JSX.Element => {
  const { env, analystData, lastPrice, cst, clientLocale, epic, instrumentName, trackInteraction } = props

  const [isHoveringArticle, setIsHoveringArticle] = useState(false)
  const [isHoveringProfile, setIsHoveringProfile] = useState(false)

  const today = new Date()
  const recommendationDate = new Date(analystData.recommendationDate)

  const dateOrTime = (today: Date, recommendationDate: Date): string => {
    if (today.toDateString() === recommendationDate.toDateString()) {
      return recommendationDate.toLocaleTimeString('en-GB').slice(0, 5)
    }
    return recommendationDate.toLocaleDateString('en-GB')
  }

  const date = dateOrTime(today, recommendationDate)

  const action = analystData.analystAction.charAt(0).toUpperCase() + analystData.analystAction.slice(1)

  const recommendation = analystData.recommendation === 'Hold'
    ? <NeutralValue data-testid='analyst-Hold'>{analystData.recommendation}</NeutralValue>
    : (analystData.recommendation === 'Buy'
        ? <PositiveValue data-testid='analyst-Buy'>{analystData.recommendation}</PositiveValue>
        : <NegativeValue data-testid='analyst-Sell'>{analystData.recommendation}</NegativeValue>
      )

  const upOrDownside = (analystPriceTarget: number, currencyCode: string, lastPrice: number | undefined): JSX.Element => {
    if (lastPrice != undefined && currencyCode != '') {
      const change = ((analystPriceTarget - lastPrice) / (lastPrice)) * 100
      if (change > 0) {
        return <PositiveValue data-testid='analyst-upside'>{change.toFixed(2)}%</PositiveValue>
      } else {
        return <NegativeValue data-testid='analyst-downside'>{change.toFixed(2)}%</NegativeValue>
      }
    } else {
      return <div data-testid='no-upside-downside'>-</div>
    }
  }

  const picture: JSX.Element = analystData.expertPictureURL === ''
    ? <div style={{ marginRight: '8px', alignSelf: 'center' }} data-testid='no-profile-picture'><NoProfilePicture size='small'/></div>
    : <ProfilePicture src={analystData.expertPictureURL} data-testid='profile-picture'/>

  /* istanbul ignore next */
  const profileTether = (ref: React.RefObject<any>) =>
    <HoverableCard ref={ref}
      isHovering={isHoveringProfile}
      onMouseEnter={() => setIsHoveringProfile(true)}
      onMouseLeave={() => setIsHoveringProfile(false)}>
      <AnalystProfile env={env} expertUID={analystData.expertUID} cst={cst}/>
    </HoverableCard>

  /* istanbul ignore next */
  const articleTether = (ref: React.RefObject<any>) =>
    <HoverableCard ref={ref}
      isHovering={isHoveringArticle}
      onMouseEnter={() => setIsHoveringArticle(true)}
      onMouseLeave={() => setIsHoveringArticle(false)}>
      <ArticlePopup
        analystName={analystData.analystName}
        articleTitle={analystData.articleTitle}
        articleQuote={analystData.articleQuote}
        articleSite={analystData.articleSite}
        url={analystData.url}
        timestamp={analystData.timestamp}
        profilePicture={picture}
        epic={epic}
        instrumentName={instrumentName}
        trackInteraction={trackInteraction}
      />
    </HoverableCard>

  return (
    <AnalystForecastContainer isHoveringArticle={isHoveringArticle} isHoveringProfile={isHoveringProfile}>
      {/* suppressing this error because Typescript is currently picking up two
      different versions of React and failing to resolve the tether types correctly.
      works fine nonetheless but will look into why it's picking up two React versions */}
      <TetherComponent
        attachment='center left'
        targetAttachment='center right'
        renderTarget={(ref) =>
          <AnalystProfileContainer
            ref={ref as React.RefObject<HTMLTableCellElement>}
            data-testid='analyst-profile-container'
            onMouseEnter={() => setIsHoveringProfile(true)}
            onMouseLeave={() => setIsHoveringProfile(false)}
          >
            {picture}
            <AnalystInfoContainer>
              <AnalystName data-testid='analyst-name'>{analystData.analystName}</AnalystName>
              <AnalystFirm data-testid='analyst-firm'>{analystData.firmName}</AnalystFirm>
              <StarsRating rating={analystData.numOfStars}/>
            </AnalystInfoContainer>
          </AnalystProfileContainer>
        }
        renderElement={(ref) => profileTether(ref)}
      />
      <RowData width='80px' minWidth='60px'>{recommendation}</RowData>
      <RowData width='100px' minWidth='80px' data-testid='analyst-price-target'>
        {gbx2DpCurrencyFormat(clientLocale, analystData.convertedPriceTargetCurrencyCode, analystData.convertedPriceTarget)}
      </RowData>
      <RowData width='110px' minWidth='90px'>{upOrDownside(analystData.convertedPriceTarget, analystData.convertedPriceTargetCurrencyCode, lastPrice)}</RowData>
      <RowData width='90px' minWidth='80px' data-testid='recommendation-date'>{date}</RowData>
      <RowData width='100px' minWidth='80px' data-testid='analyst-action'>{action}</RowData>
      <TetherComponent
        attachment='center right'
        targetAttachment='center left'
        renderTarget={(ref) =>
          <RowData width='50px' minWidth='50px'
            ref={ref as React.RefObject<HTMLTableCellElement>}
            style={{ display: 'flex', justifyContent: 'center', height: '45px', alignItems: 'center' }}
            data-testid='article-icon'
            onMouseEnter={() => setIsHoveringArticle(true)}
            onMouseLeave={() => setIsHoveringArticle(false)}
            article>
            <ArticleIcon/>
          </RowData>
        }
        renderElement={(ref) => articleTether(ref)}
      />
    </AnalystForecastContainer>
  )
}

const NegativeValue = styled.span`color: ${({ theme }) => theme.color.text.negativeAmount.value};`

const PositiveValue = styled.span`color: ${({ theme }) => theme.color.text.positiveAmount.value};`

const NeutralValue = styled.span`color: ${({ theme }) => theme.color.text.tertiary.value};`

const AnalystForecastContainer = styled.tr<{
  isHoveringArticle: boolean
  isHoveringProfile: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 3px 0px 3px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  background: ${({ theme, isHoveringArticle, isHoveringProfile }) => (isHoveringArticle || isHoveringProfile) ? theme.color.separator.primary.background.value : ''};
`

const AnalystForecastTBody = styled.tbody`
  display: block;
  width: 100%;
  min-width: 648px;
  height: 260px;
  overflow: hidden auto;
`

const AnalystProfileContainer = styled.td`
  display: flex;
  flex-direction: row;
  width: 190px;
  min-width: 160px;
  padding: 0px;
`

const ProfilePicture = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  background-size: 100% 100%;
  align-self: center;
`

const AnalystInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 120px;
`

const AnalystName = styled.div`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-weight: ${({ theme }) => theme.font.heading.h4.weight.value};
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const AnalystFirm = styled.div`
  color: ${({ theme }) => theme.color.text.secondary.value};
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const RowData = styled.td<{
  width: string
  minWidth: string
  article?: boolean
}>`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  text-align: ${({ article }) => article ? '' : 'right'};
  padding: 0px;
`

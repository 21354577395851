export const circleTextColor = (score: number, colPrimary: string, inverted: string): string => {
  return (score >= 3 && score <= 9) ? colPrimary : inverted
}

export const circleBgColor = (score: number, colShort: string, colLong: string): string => {
  let circleBgColor = score <= 5 ? colShort : colLong

  // background circleBgColor opacity hex value
  if (score === 2 || score === 9) circleBgColor += 'CC' // 80%
  else if (score === 3 || score === 8) circleBgColor += '99' // 60%
  else if (score === 4 || score === 7) circleBgColor += '66' // 40%
  else if (score === 5 || score === 6) circleBgColor += '33' // 20%

  return circleBgColor
}

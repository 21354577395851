import { AppProps, VIEW, View } from './AppProps'
import styled from 'styled-components'
import { TipRankLogo } from '@ig-caa/media'
import { App as SmartScoreApp } from './smart-score/App'
import { App as AnalystConsensusApp } from './analyst-consensus/App'
import { Link } from 'ig-phoenix'
import { useTranslation } from '@ig-caa/i18n'

interface HomeProps extends AppProps {
  setView: (view: View) => void
}

export default function Home (props: HomeProps) {
  const { t } = useTranslation('translation')

  return (
    <>
      <IntroText>
        <span data-testid='expert-analysis-intro-text'>
          Enhance your trading with data-driven market insights and analysis powered by TipRanks.
          See a stock’s performance at a glance with ‘Smart Score’, or discover market forecasts from experts with ‘Analyst Consensus’.
        </span>
        <span className='tipranks-logo' data-testid='tipranks-logo'><TipRankLogo /></span>
      </IntroText>
      <Apps>
        <AppContainer className='expert-analysis_smart-score-mini' data-testid='expert-analysis_smart-score'>
          <AppHeader>{'Smart Score'}</AppHeader>
          <SmartScoreApp
            {...props}
            toggleMiniView={() => props.setView(VIEW.SMART_SCORE)}
            trackInteraction={props.trackInteraction as any}
            miniView={true}
            env={props.env as any}
            theme={props.theme as any}
            sessionData={props.sessionData as any}
            marketId={props.tipranksMarketId} />
        </AppContainer>
        <AppContainer className='expert-analysis_analyst-consensus-mini' data-testid='expert-analysis_analyst-consensus'>
          <AppHeader>{'Analyst Consensus'}</AppHeader>
          <AnalystConsensusApp
            {...props}
            toggleMiniView={() => props.setView(VIEW.ANALYST_CONSENSUS)}
            trackInteraction={props.trackInteraction as any}
            miniView={true}
            env={props.env as any}
            theme={props.theme as any}
            sessionData={props.sessionData as any}
            marketId={props.tipranksMarketId} />
        </AppContainer>
      </Apps>
      <Disclaimer data-testid='disclaimer-learn-more'>
        <Link href={`${t('disclaimer-link')}${t('disclaimer-link-suffix')}`} target='_blank' data-testid='disclaimer-learn-more-link'>
          Learn more
        </Link>
        {' '}
        about Smart Score and Analyst Consensus.
      </Disclaimer>
      <Disclaimer data-testid='disclaimer'>
        {t('disclaimer')}
      </Disclaimer>
    </>
  )
}

const IntroText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  font-weight: ${({ theme }) => theme.font.body.weight.value};
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  color: ${({ theme }) => theme.color.text.primary.value};
  max-width: 800px;
  margin-bottom: 16px;
  display: flex;
  gap: 11px;
  flex-direction: row;
`

const AppHeader = styled.span`
  color: ${({ theme }) => theme.color.text.secondary.value};
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  gap: 8px;
  flex-direction: row;
`
const Apps = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 8px;
`
const AppContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex: 0 1 auto;
`

const Disclaimer = styled.div`
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  margin-bottom: 4px;
`

import { NoAnalystConsensus } from '@ig-caa/media'
import styled from 'styled-components'
import { ErrorProps } from '../../utils/getErrorType'

/**
 * Error Page component for the full view of Analyst Consensus
 * for Mini View Error Page, go to ./miniView/MiniErrorPage
 */
export default function ErrorPage ({ pageType }: ErrorProps): JSX.Element {
  if (pageType === 'EMPTY_DATA') {
    return (
      <LeftAlignedPage data-testid='error-no-data-left'>
        <Icon><NoAnalystConsensus /></Icon>
        <Message>No Analyst Consensus available yet</Message>
      </LeftAlignedPage>
    )
  } else {
    return (
      <Page data-testid='loading-error'>
        <ErrorText>
          {'We’re not able to load this data right now'} <br/> {'Please come back later'}
        </ErrorText>
      </Page>
    )
  }
}

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-width: 200px;
  font-weight: 400;
  height: 96px;
  color: ${({ theme }) => theme.color.text.secondary.value};
`
const LeftAlignedPage = styled(Page)`
  height: auto;
  align-items: start;
  justify-content: start;
`

export const Icon = styled.div`
  height: 24px;
  width: 24px;
`

export const Message = styled.div`
  margin-left: 16px;
  font-size: 13px;
  line-height: 24px;
`

export const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  text-align: center;
`

export const enum Theme {
  Light = 'Light',
  Dark = 'Dark'
}

export const enum ENVIRONMENTS {
  test = 'test',
  uat = 'uat',
  demo = 'demo',
  prod = 'prod'
}

export const enum CONSENSUS {
  BUY = 'Buy',
  STRONG_BUY = 'StrongBuy',
  SELL = 'Sell',
  STRONG_SELL = 'StrongSell',
  HOLD = 'Neutral',
  NA = 'N/A'
}

export const enum TRACKING_ACTIONS {
  ANALYST_CONSENSUS_VIEW_MORE = 'Click view more Analyst Consensus',
  SMART_SCORE_VIEW_MORE = 'Click view more Smart Score',
  OPEN_ARTICLE = 'Open article'
}

export type TrackInteraction = (eventAction: TRACKING_ACTIONS, eventLabel: string, epic: string, instrumentName: string) => void

export interface AppProps {
  theme: Theme
  cst: string
  sessionData: {
    clientLocale: string
    currentAccountId: string
    liveClientId: string
    webSiteId: string
    igCompany: string
  }
  env: ENVIRONMENTS
  marketId: string
  miniView: boolean
  toggleMiniView: Function
  epic: string
  instrumentName: string
  trackInteraction: TrackInteraction
}

export interface AnalystConsensusProps {
  marketId: string
  env: ENVIRONMENTS
  cst: string
  clientLocale: string
  epic: string
  instrumentName: string
  trackInteraction: TrackInteraction
}

export interface MiniViewProps {
  toggleMiniView: Function
  marketId: string
  env: ENVIRONMENTS
  cst: string
  epic: string
  instrumentName: string
  trackInteraction: TrackInteraction
}

export interface AnalystProfileProps {
  expertUID: string
  env: ENVIRONMENTS
  cst: string
}

export interface StockRatingCardProps {
  overviewData: StockOverviewData | undefined
  clientLocale: string
  overviewLoading: boolean
  isOverviewError: boolean
  overviewError: any
}

export interface AnalystForecastCardProps {
  analystsData: StockAnalystData[] | undefined
  clientLocale: string
  analystsLoading: boolean
  isAnalystError: boolean
  analystsError: any
  lastPriceData: {
    lastPrice: number | undefined
    env: ENVIRONMENTS
    cst: string
  }
  epic: string
  instrumentName: string
  trackInteraction: TrackInteraction
}

/**
 * Used to display the summary of a market
 */
export interface StockOverviewData {
  lowPriceTarget: number
  highPriceTarget: number
  priceTarget: number
  buy: number
  sell: number
  hold: number
  consensus: CONSENSUS
  priceTargetUpside: number
  ticker: string
  companyName: string
  priceTargetCurrencyCode: string
  totalAnalysts: number
  lastPrice: number
}

/**
 * Used to display a table of analysts and their forecasts
 */
export interface StockAnalystData {
  analystName: string
  firmName: string
  recommendation: string
  recommendationDate: Date
  expertUID: string
  url: string
  expertPictureURL: string
  analystRank: number
  numberOfRankedExperts: number
  successRate: number
  excessReturn: number
  totalRecommendations: number
  goodRecommendations: number
  numOfStars: number
  stockSuccessRate: number
  stockAvgReturn: number
  articleTitle: string
  articleSite: string
  articleQuote: string
  priceTarget: number
  priceTargetCurrency: number
  priceTargetCurrencyCode: string
  convertedPriceTarget: number
  convertedPriceTargetCurrency: number
  convertedPriceTargetCurrencyCode: string
  ticker: string
  timestamp: Date
  analystAction: string
  stockTotalRecommendations: number
  stockGoodRecommendations: number
}

/**
 * AnalystProfile
 * Used to display key details about an analyst
 */
export interface AnalystProfileData {
  expertPictureUrl: string
  analystName: string
  firmName: string
  numOfStars: number
  goodRecommendations: number
  totalRecommendations: number
  successRate: number
  excessReturn: number
  mainSector: string
  ratingDistribution: {
    formattedCountries: string
    buyPercent: number
    holdPercent: number
    sellPercent: number
  }
}

import styled from 'styled-components'

// Black triangle
export const ScaleIndicator = styled.div<{
  size?: string
  animationName: string
  loadAnimation: boolean
}>`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent ${({ theme }) => theme.color.icons.primary.value} transparent;
  margin: ${({ size }) => `${size === 'mini' ? '4' : size === 'small' ? '11' : '15'}px 0 0`};
  position: relative;
  animation: ${({ animationName }) => `${animationName} ease-in-out forwards`};
  animation-duration: ${({ loadAnimation }) => loadAnimation ? '800ms' : '0ms'};
  left: calc(50% - 8px);
  
  // -8px for size of the indicator
  @keyframes slideTo1 { 100% { left: calc(5% - 8px) }}
  @keyframes slideTo2 { 100% { left: calc(15% - 8px) }}
  @keyframes slideTo3 { 100% { left: calc(25% - 8px) }}
  @keyframes slideTo4 { 100% { left: calc(35% - 8px) }}
  @keyframes slideTo5 { 100% { left: calc(45% - 8px) }}
  @keyframes slideTo6 { 100% { left: calc(55% - 8px) }}
  @keyframes slideTo7 { 100% { left: calc(65% - 8px) }}
  @keyframes slideTo8 { 100% { left: calc(75% - 8px) }}
  @keyframes slideTo9 { 100% { left: calc(85% - 8px) }}
  @keyframes slideTo10 { 100% { left: calc(95% - 8px) }}
`

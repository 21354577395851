import { ENVIRONMENTS, StockOverviewData } from '../components/analyst-consensus/AppProps'
import { ERROR_TYPE } from './getErrorType'
import { getData, isKnownError, validateRes } from './validationHelpers'

const ENDPOINTS = (marketId: string) => ({
  prod: `https://tipranks-api.ig.com/tipranks/api/stock-overview/${marketId}`,
  test: `https://net-tipranks-api.ig.com/tipranks/api/stock-overview/${marketId}`,
  uat: `https://web-tipranks-api.ig.com/tipranks/api/stock-overview/${marketId}`,
  demo: `https://demo-tipranks-api.ig.com/tipranks/api/stock-overview/${marketId}`,
  local: `https://net-tipranks-api.ig.com/tipranks/api/stock-overview/${marketId}`
})

export default async function fetchStockOverview (env: keyof typeof ENVIRONMENTS, marketId: string, cst: string): Promise<StockOverviewData> {
  try {
    if (marketId === '') {
      throw new Error('missing marketId')
    }
    const data = await fetch(`${ENDPOINTS(marketId)[env]}`, {
      headers: {
        cst
      }
    }).then(validateRes).then(getData)

    return data
  } catch (error) {
    if (!isKnownError(error)) {
      throw new Error(ERROR_TYPE.DEFAULT)
    }

    switch (error.message) {
      case '404':
        throw new Error(ERROR_TYPE.NO_TIPRANKS)
      case 'PARSE_ERROR':
        throw new Error(ERROR_TYPE.EMPTY_DATA)
      default:
        throw new Error(ERROR_TYPE.DEFAULT)
    }
  }
}

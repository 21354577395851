import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import Scale from './score-display/Scale'
import { Icon, Message } from './ErrorPage'
import { Circle } from './score-display/Circle'
import { circleTextColor, circleBgColor } from './score-display/Helpers'
import { MainHeading } from './score-display/Heading'
import { ScaleIndicator } from './score-display/Indicator'
import PerformanceTooltip from './PerformanceTooltip'
import { LIGHT_THEME } from './AppProps'
import { NoSmartScore } from '@ig-caa/media'

export default function ScoreDisplay ({ score }: { score: number | null }): JSX.Element {
  const theme = useTheme()
  const colShort = theme.color.text.short.value
  const colLong = theme.color.text.long.value
  const colPrimary = theme.color.text.primary.value
  const colTertiary = theme.color.text.tertiary.value
  const colPrimaryInverted = theme.color.text.inverted.value
  const indicatorColor = theme.color.icons.primary.value
  const [loadAnimation, setLoadAnimation] = useState(true)

  if (score === null) {
    return (
      <Card data-testid='no-smart-score-value' className='no-smart-score-value'>
        <Icon><NoSmartScore /></Icon>
        <Message>No score available yet</Message>
      </Card>
    )
  }
  setTimeout(() => {
    setLoadAnimation(false)
    // prevents re-animation of the Indicator sliding again when resizing the screen from small width to medium to large
  }, 1200)
  const title = (score <= 3 ? 'Underperform' : (score <= 7 ? 'Neutral' : 'Outperform'))
  const titleColor = (score <= 3 ? colShort : (score <= 7 ? colTertiary : colLong))
  const scale = Scale(score, false, colShort, colLong, colPrimary, colTertiary, indicatorColor)
  /* istanbul ignore next */
  const circleTextCol = theme.name === LIGHT_THEME ? colPrimaryInverted : colPrimary

  return (
    <Card data-testid='score-display-card' className='score-display-card'>
      <Circle
        bgColor={circleBgColor(score, colShort, colLong)}
        textColor={circleTextColor(score, colPrimary, circleTextCol)}
        animationName={`fadeScore${score}`}
        score={score}
        classname='main-circle'
      />
      <Right className='score-display-header-container'>
        <div className='score-display-header'>
          <MainHeading color={titleColor} data-testid='heading' className='main-heading'>{title}</MainHeading>
          <PerformanceTooltip />
        </div>
        <div className='right-side-indicator'>
          <ScaleContainer className='right-side-scales'>{scale}</ScaleContainer>
          <ScaleIndicator className='scale-pointer-right' animationName={`slideTo${score}`} loadAnimation={loadAnimation}/>
        </div>
      </Right>

      <BreakLine />
      <div className='bottom-side-indicator' data-testid='bottom-side-indicator'>
        <ScaleContainer2 className='bottom-side-scales'>{scale}</ScaleContainer2>
        <ScaleIndicator className='scale-indicator-bottom' data-testid='scale-indicator-bottom' size='small' animationName={`slideTo${score}`} loadAnimation={loadAnimation}/>
      </div>
    </Card>
  )
}

const Card = styled.div`
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  min-width: 203px;
  max-width: 792px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  border-radius: 4px;
  user-select: none;
  &.no-smart-score-value {
    padding: 16px 32px;
  }
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

// ScaleContainer is for the larger view when the indicator and heading label is to the right of the Number in Circle
const ScaleContainer = styled.div`
  display: flex;
  column-gap: 1px;
  flex-grow: 1;
`

// Container 2 is for the small view when the indicator is below both the number and score heading label
const ScaleContainer2 = styled(ScaleContainer)`
  margin-top: 8px;
`

const BreakLine = styled.div`
  flex-basis: 100%;
  height: 0;
`

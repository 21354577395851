import '../../../styles/ACMiniView.css'
import { MiniViewProps, StockOverviewData, TRACKING_ACTIONS } from '../AppProps'
import styled from 'styled-components'
import MiniErrorPage from './MiniErrorPage'
import { useQuery } from '@tanstack/react-query'
import fetchStockOverview from '../../../utils/fetchStockOverview'
import StockRating from '../stock-rating/StockRating'
import { Spinner } from 'ig-phoenix'
import { validateAnalystConsensusMiniData } from '../../../utils/validationHelpers'
import { getErrorType } from '../../../utils/getErrorType'

export default function MiniView (props: MiniViewProps): JSX.Element {
  const { toggleMiniView, marketId, env, cst, epic, instrumentName, trackInteraction } = props

  const { isLoading, isError, error, data } = useQuery<StockOverviewData, any>({
    queryKey: ['stockOverview', marketId],
    queryFn: async () => await fetchStockOverview(env, marketId, cst).then(validateAnalystConsensusMiniData),
    refetchOnWindowFocus: false
  })

  if (isLoading) {
    return <div className='analyst-consensus-loading' data-testid='isLoading'><Spinner radius='40px'/></div>
  }

  if (isError) {
    return <MiniErrorPage pageType={getErrorType(error?.message)} />
  }

  return (
    <div className='mini-view' data-testid='analyst-consensus_mini-view'>
      <Card data-testid='mini-view-card'>
        <StockRating stockData={data} miniView={true}/>
        <Summary className='analyst-consensus-summary' data-testid='mini-summary'>
          Based on <b>{data.totalAnalysts}</b> analysts giving stock ratings to <b>{data.companyName}</b> in the past 3 months
        </Summary>
        <MoreLinkElement
          className='view-more-link'
          data-testid='analyst-consensus-mini-more-link'
          onClick={function () {
            toggleMiniView()
            trackInteraction(TRACKING_ACTIONS.ANALYST_CONSENSUS_VIEW_MORE, 'View more', epic, instrumentName)
          }}
        >
          View more
        </MoreLinkElement>
      </Card>
    </div>
  )
}

const Summary = styled.div`
  font-weight: 400;
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  line-height: 16px;
`

const MoreLinkElement = styled.a`
  font-weight: 400;
  font-size: 11px;
  line-height: ${({ theme }) => theme.size.link.medium.lineHeight.value};
  color: ${({ theme }) => theme.color.text.link.value};
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
`

const Card = styled.div`
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  border-radius: 8px;
  min-width: 200px;
  max-width: 392px;
  height: 208px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  box-sizing: border-box;
`

import React from 'react'
import styled from 'styled-components'

interface CircleProps {
  textColor: string
  bgColor: string
  animationName?: string
  score: number
  classname: string
}

export function Circle ({ textColor, bgColor, score, classname, animationName }: CircleProps): JSX.Element {
  if (animationName === null || animationName === undefined) {
    return (
      <SmallCircle data-testid={classname} bgColor={bgColor} textColor={textColor} className={classname}>
        {score}
      </SmallCircle>
    )
  }

  if (classname === 'mini-view-circle') {
    return (
      <AnimatedCircle data-testid={classname} className='mini-view-circle' bgColor={bgColor} textColor={textColor} animationName={animationName}>
        {score}
      </AnimatedCircle>
    )
  }

  return (
    <AnimatedCircle data-testid={classname} className={classname} bgColor={bgColor} textColor={textColor} animationName={animationName}>
      {score}
    </AnimatedCircle>
  )
}

export const SmallCircle = styled.div<{
  bgColor: string
  textColor: string
}>`
  max-width: 32px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  flex-grow: 1;
  border-radius: 50%;
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  margin-right: 8px;
`

/**
 * AnimatedCircle (inherited from Circle): Smart Score in an animated circle when first loaded
 * Must provide `animationName` which are explicitly named (fadeScore1 to fadeScore10)
 */
export const AnimatedCircle = styled(SmallCircle)<{
  animationName: string
}>`
  max-width: var(--size);
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  font-size: var(--fontSize);
  
  animation: ${({ animationName }) => `${animationName} 800ms ease-in-out forwards`};
  background-color: ${({ theme }) => theme.color.background.primary.value};
  @keyframes fadeScore1 { 100% { background-color: #B72122; }}
  @keyframes fadeScore2 { 100% { background-color: #B72122CC; }}
  @keyframes fadeScore3 { 100% { background-color: #B7212299; }}
  @keyframes fadeScore4 { 100% { background-color: #B7212266; }}
  @keyframes fadeScore5 { 100% { background-color: #B7212233; }}
  @keyframes fadeScore6 { 100% { background-color: #2B70AE33; }}
  @keyframes fadeScore7 { 100% { background-color: #2B70AE66; }}
  @keyframes fadeScore8 { 100% { background-color: #2B70AE99; }}
  @keyframes fadeScore9 { 100% { background-color: #2B70AECC; }}
  @keyframes fadeScore10 { 100% { background-color: #2B70AE; }}
`

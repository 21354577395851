import '../../../styles/ACStockRating.css'
import styled, { useTheme } from 'styled-components'
import { ArrowDown, ArrowUp } from '@ig-caa/media'
import { H1, Title } from '../helpers'

export default function Change ({ priceTargetUpside }: { priceTargetUpside: number }): JSX.Element {
  const theme = useTheme()

  const textColor = priceTargetUpside > 0
    ? theme.color.text.positiveMarketMovement.value
    : priceTargetUpside < 0
      ? theme.color.text.negativeMarketMovement.value
      : theme.color.text.tertiary.value

  return (
    <ChangeContainer data-testid='change-container' textColor={ textColor }>
      <H1 data-testid='change'>{ priceTargetUpside.toFixed(2) }</H1>
      <Percentage>%</Percentage>
      <Sign>{ priceTargetUpside > 0 ? 'Upside' : priceTargetUpside < 0 ? 'Downside' : '' }</Sign>
      { priceTargetUpside > 0 ? <ArrowUp/> : priceTargetUpside < 0 ? <ArrowDown/> : '' }
      <Title>Change</Title>
    </ChangeContainer>
  )
}

const ChangeContainer = styled.div<{
  textColor: string
}>`
  color: ${({ textColor }) => textColor};
  padding-top: 12px;
`

const Percentage = styled.span`
  font-size: 15px;
  line-height: 22px;
  font-weight: medium;
`

const Sign = styled.span`
  font-size: 15px;
  line-height: 22px;
  font-weight: medium;
  margin-left: 4px;
  margin-right: 4px;
`

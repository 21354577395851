export interface ErrorProps {
  pageType?: ErrorType
}

export const ERROR_TYPE = {
  EMPTY_DATA: 'EMPTY_DATA',
  NO_TIPRANKS: 'NO_TIPRANKS',
  FETCH_ERROR: 'FETCH_ERROR',
  DEFAULT: 'DEFAULT'
} as const

export type ErrorType = typeof ERROR_TYPE[keyof typeof ERROR_TYPE]

export const getErrorType = (errorMessage?: ErrorType): ErrorType => {
  if (!errorMessage) {
    return ERROR_TYPE.DEFAULT
  }

  return errorMessage
}

import styled from 'styled-components'

export default function Heading (): JSX.Element {
  return (
    <TableHeadingContainer data-testid='forecast-table-heading'>
      <TableHeading width='190px' minWidth='160px'>ANALYST PROFILE</TableHeading>
      <TableHeading width='80px' minWidth='60px' align='right'>POSITION</TableHeading>
      <TableHeading width='100px' minWidth='80px' align='right'>PRICE TARGET</TableHeading>
      <TableHeading width='110px' minWidth='90px' align='right'>UP / DOWNSIDE</TableHeading>
      <TableHeading width='90px' minWidth='80px' align='right'>DATE</TableHeading>
      <TableHeading width='100px' minWidth='80px' align='right'>ACTION</TableHeading>
      <TableHeading width='50px' minWidth='50px' align='center'>ARTICLE</TableHeading>
    </TableHeadingContainer>
  )
}

const TableHeadingContainer = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;
  position: relative;
`

const TableHeading = styled.th<{
  width: string
  minWidth: string
  align?: string
}>`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  text-align: ${({ align }) => align || 'left'};
  padding: 0px;
  font-weight: normal;
`

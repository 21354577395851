import '../../../styles/ACStockRating.css'
import { StockOverviewData } from '../AppProps'
import styled from 'styled-components'
import RatingsDoughnut from './RatingsDoughnut'
import PriceTarget from './PriceTarget'
import Change from './Change'

interface StockRatingProps {
  stockData: StockOverviewData
  miniView: false
  clientLocale: string
}

interface MiniStockRatingProps {
  stockData: StockOverviewData
  miniView: true
}

interface ConsensusProps {
  stockData: StockOverviewData
  miniView: boolean
}

export default function StockRating (props: StockRatingProps | MiniStockRatingProps): JSX.Element {
  const { stockData, miniView } = props
  return (
    <>
      {miniView &&
        <RatingsContainer>
          <Consensus stockData={stockData} miniView/>
          <RatingsDoughnut stockData={stockData} />
        </RatingsContainer>
      }
      {!miniView &&
        <div className='rating-price-change-container' data-testid={'rating-price-change-container'}>
          <RatingsContainer>
            <Consensus stockData={stockData} miniView={miniView}/>
            <RatingsDoughnut stockData={stockData} />
          </RatingsContainer>
          <PriceTargetAndChangeContainer priceTargetUpside={stockData.priceTargetUpside} data-testid='price-target-change-container'>
            <PriceTarget priceTarget={stockData.priceTarget} currencyCode={stockData.priceTargetCurrencyCode} change={stockData.priceTargetUpside} clientLocale={props.clientLocale}/>
            <Change priceTargetUpside={stockData.priceTargetUpside} />
          </PriceTargetAndChangeContainer>
        </div>
      }
    </>
  )
}

function Consensus (props: ConsensusProps): JSX.Element {
  const { stockData, miniView } = props
  const CONSENSUS_TO_VALUE = {
    Buy: PositiveValue,
    StrongBuy: PositiveValue,
    Sell: NegativeValue,
    StrongSell: NegativeValue,
    Neutral: NeutralValue,
    'N/A': NeutralValue
  } as const

  const DISPLAY_CONSENSUS = {
    Buy: 'Buy',
    StrongBuy: 'Strong buy',
    Sell: 'Sell',
    StrongSell: 'Strong sell',
    Neutral: 'Hold',
    'N/A': 'N/A'
  } as const

  const ValueComp = CONSENSUS_TO_VALUE[stockData.consensus]

  return (
    <ConsensusContainer miniView={miniView} data-testid='consensus-container'>
      <Header data-testid={`consensus-${stockData.consensus}`}>
        <ValueComp>{DISPLAY_CONSENSUS[stockData.consensus]}</ValueComp>
      </Header>
      <div className='list-item'>
        <PositiveSquare /><PositiveValue><Number>{stockData.buy}</Number><BodyText>Buy</BodyText></PositiveValue>
      </div>
      <div className='list-item'>
        <NeutralSquare /><NeutralValue><Number>{stockData.hold}</Number><BodyText>Hold</BodyText></NeutralValue>
      </div>
      <div className='list-item'>
        <NegativeSquare /><NegativeValue><Number>{stockData.sell}</Number><BodyText>Sell</BodyText></NegativeValue>
      </div>
    </ConsensusContainer>
  )
}

const ConsensusContainer = styled.div<{
  miniView: boolean
}>`
  width: 123px;
  margin-right: ${({ miniView }) => miniView ? '29px' : '8px'}
`

const RatingsContainer = styled.div`
  display: flex;
`

const Header = styled.div`
  font-size: ${({ theme }) => theme.size.heading.h1.text.value};
  line-height: ${({ theme }) => theme.size.heading.h1.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
  margin-bottom: 16px;
  text-wrap: nowrap;
`

const Number = styled.span`
  font-size: ${({ theme }) => theme.size.body.medium.text.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
  margin-right: 4px;
`

const BodyText = styled.span`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  font-weight: 400;
`

const NegativeValue = styled.span`color: ${({ theme }) => theme.color.text.negativeAmount.value};`

const PositiveValue = styled.span`color: ${({ theme }) => theme.color.text.positiveAmount.value};`

const NeutralValue = styled.span`color: ${({ theme }) => theme.color.text.tertiary.value};`

const NegativeSquare = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.text.negativeAmount.value};
  display: inline-block;
  margin-right: 4px;
`

const PositiveSquare = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.text.positiveAmount.value};
  display: inline-block;
  margin-right: 4px;
`

const NeutralSquare = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.toggle.disabled.background.value};
  display: inline-block;
  margin-right: 4px;
`

const PriceTargetAndChangeContainer = styled.div<{
  priceTargetUpside: number
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px 32px;
  flex-wrap: wrap;
`

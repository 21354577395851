import React from 'react'
import '../../styles/SmartScore.css'
import styled from 'styled-components'
import { Spinner, Link } from 'ig-phoenix'
import { SmartScoreData, SmartScoreProps } from './AppProps'
import ErrorPage from './ErrorPage'
import ScoreDisplay from './ScoreDisplay'
import { useTranslation } from '@ig-caa/i18n'
import DataCards from './DataCards'
import { useQuery } from '@tanstack/react-query'
import fetchSmartScore from '../../utils/fetchSmartScore'
import { TipRankLogo } from '@ig-caa/media'
import { validateSmartScoreData } from '../../utils/validationHelpers'
import { getErrorType } from '../../utils/getErrorType'

export default function SmartScore (props: SmartScoreProps): JSX.Element {
  const { t } = useTranslation('translation') // name of the first property inside resources when calling `init` ?
  const { marketId, locale, env, cst } = props

  const { isLoading, isError, error, data } = useQuery<SmartScoreData, any>({
    queryKey: ['smartscore', marketId],
    queryFn: async () => await fetchSmartScore(env, marketId, cst).then(validateSmartScoreData),
    refetchOnWindowFocus: false
  })

  if (isLoading) {
    return <div className="smart-score-loading" data-testid='isLoading'><Spinner radius='40px' /></div>
  }

  if (isError) {
    return <ErrorPage pageType={getErrorType(error?.message)} />
  }

  return (
    <AppContainer className='smart-score' data-testid='smart-score_full-view'>
      <div className='smart-score-container'>
        <div className="smart-score-heading">
          <span><b className="heading-title">Smart Score</b></span>
          <div className='tipranks-logo'><TipRankLogo /></div>
        </div>
        <DataRefreshedText>Data updates every day by 6am ET</DataRefreshedText>
        <ScoreDisplay score={data.smartScore} />
        <SmartScoreHeader>Smart Score Data Sets</SmartScoreHeader>
        <DataCards data={data} locale={locale} />
        <Footer className='fundamentals-summary' data-testid='summary'>
          {`The Smart Score is a quantitative, data-driven rating system and does not include human intervention.
          Six of the eight data sets that contribute to the Smart Score calculation are unique to TipRanks – only the technicals and fundamentals are not.`}
        </Footer>
        <Footer data-testid='disclaimer-learn-more'>
          <Link href={`${t('disclaimer-link')}${t('disclaimer-link-suffix')}`} target='_blank' data-testid='disclaimer-learn-more-link'>
            Learn more
          </Link>
          {' '}
          about Smart Score data sets.
        </Footer>
        <Footer data-testid='disclaimer'>
          {t('disclaimer')}
        </Footer>
      </div>
    </AppContainer>
  )
}

const AppContainer = styled.div`
  background-color: ${({ theme }) => theme.color.background.secondarySurface.value};
  color: ${({ theme }) => theme.color.text.primary.value};
`
const DataRefreshedText = styled.p`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  line-height: ${({ theme }) => theme.size.body.small.lineHeight.value};
  margin: 8px 0;
`
const SmartScoreHeader = styled.p`
  letter-spacing: 2px;
  color: ${({ theme }) => theme.color.text.tertiary.value};
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  margin: 16px 0 0;
`
const Footer = styled.div`
  color: ${({ theme }) => theme.color.text.secondary.value};
  font-size: 11px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 4px;
`

import React from 'react'
import { SmartScoreData } from './AppProps'
import '../../styles/SmartScore.css'
import styled from 'styled-components'
import { roundBigNumbers, percent, money } from '../../utils/numberFormatters'

export default function DataCards ({ data, locale }: { data: SmartScoreData, locale: string }): JSX.Element {
  const {
    hedgeFundTrendValue,
    bloggerSectorAvg,
    bloggerBullishSentiment,
    insidersLast3MonthsSum,
    newsSentimentsBearishPercent,
    newsSentimentsBullishPercent,
    investorHoldingChangeLast7Days,
    investorHoldingChangeLast30Days,
    priceTarget,
    fundamentalsReturnOnEquity,
    fundamentalsAssetGrowth,
    technicalsTwelveMonthsMomentum,
    sma,
    analystConsensus,
    hedgeFundTrend,
    insiderTrend,
    investorSentiment,
    newsSentiment,
    bloggerConsensus,
    priceTargetCurrencyCode
  } = data

  const currencyCode = priceTargetCurrencyCode.toUpperCase()
  const avgPriceTarget = (currencyCode === 'GBX' && priceTarget !== null)
    ? (priceTarget.toFixed(2) + 'p')
    : money(locale, currencyCode, priceTarget, 2)
  const last3MonthsSum = (currencyCode === 'GBX' && insidersLast3MonthsSum !== null)
    ? money(locale, 'GBP', insidersLast3MonthsSum / 100, 1)
    : money(locale, currencyCode, insidersLast3MonthsSum, 1)

  const bloggerBullishSentimentPercent = percent(bloggerBullishSentiment, 0)
  const bloggerSectorAvgPercent = percent(bloggerSectorAvg, 0)
  const investorLast7Days = percent(investorHoldingChangeLast7Days, 1)
  const investorLast30days = percent(investorHoldingChangeLast30Days, 1)
  const newsBullish = percent(newsSentimentsBullishPercent, 0)
  const newsBearish = percent(newsSentimentsBearishPercent, 0)
  const technicals12MonthMomentum = percent(technicalsTwelveMonthsMomentum, 2)
  const fundamentalsEquity = percent(fundamentalsReturnOnEquity, 2)
  const fundamentalsGrowth = percent(fundamentalsAssetGrowth, 2)

  return (
    <>
      <div className="cards-container" data-testid='cards-container'>
        <Card data-testid='analyst-card'>
          <CardHeader>Analyst Consensus</CardHeader>
          {analystConsensus === 'ModerateBuy' ? <CardBody data-testid='analyst-ModerateBuy'><PositiveValue>Moderate Buy</PositiveValue></CardBody> : null }
          {analystConsensus === 'StrongBuy' ? <CardBody data-testid='analyst-StrongBuy'><PositiveValue>Strong Buy</PositiveValue></CardBody> : null }
          {analystConsensus === 'ModerateSell' ? <CardBody data-testid='analyst-ModerateSell'><NegativeValue>Moderate Sell</NegativeValue></CardBody> : null }
          {analystConsensus === 'StrongSell' ? <CardBody data-testid='analyst-StrongSell'><NegativeValue>Strong Sell</NegativeValue></CardBody> : null }
          {analystConsensus === 'Hold' ? <CardBody data-testid='analyst-Hold'><NeutralValue>{analystConsensus}</NeutralValue></CardBody> : null }
          {analystConsensus === 'N/A' || analystConsensus === null ? <CardBody data-testid='analyst-N/A'><NeutralValue>—</NeutralValue></CardBody> : null}
          {analystConsensus !== 'N/A' && analystConsensus !== null
            ? <CardFooter data-testid='analyst-footer'>Average Price Target: <CardHeader>{avgPriceTarget}</CardHeader></CardFooter>
            : null
          }
        </Card>

        <Card data-testid='blogger-card'>
          <CardHeader>Blogger Sentiment</CardHeader>
          {bloggerConsensus === 'Bullish' ? <CardBody data-testid='blogger-Bullish'><PositiveValue>{bloggerConsensus}</PositiveValue></CardBody> : null}
          {bloggerConsensus === 'Bearish' ? <CardBody data-testid='blogger-Bearish'><NegativeValue>{bloggerConsensus}</NegativeValue></CardBody> : null}
          {bloggerConsensus === 'Neutral' ? <CardBody data-testid='blogger-Neutral'><NeutralValue>{bloggerConsensus}</NeutralValue></CardBody> : null}
          {bloggerConsensus === 'Unknown' || bloggerConsensus === null ? <CardBody data-testid='blogger-Unknown'><NeutralValue>—</NeutralValue></CardBody> : null}
          {bloggerConsensus !== 'Unknown' && bloggerConsensus !== null
            ? <>
                <CardFooter data-testid='blogger-sentiment-footer'>
                  <CardHeader>{DisplayTicker(data.ticker)}</CardHeader> Sentiment <CardHeader>{bloggerBullishSentimentPercent}</CardHeader>
                </CardFooter>
                <CardFooter data-testid='blogger-sector-average'>
                  Sector Average <CardHeader>{bloggerSectorAvgPercent}</CardHeader>
                </CardFooter>
              </>
            : null
          }
        </Card>

        <Card data-testid='hedge-fund-card'>
          <CardHeader>Hedge Fund Trend</CardHeader>
          {hedgeFundTrend === 'Decreased' ? <CardBody data-testid='hedge-Decreased'><NegativeValue>{hedgeFundTrend}</NegativeValue></CardBody> : null}
          {hedgeFundTrend === 'Increased' ? <CardBody data-testid='hedge-Increased'><PositiveValue>{hedgeFundTrend}</PositiveValue></CardBody> : null}
          {hedgeFundTrend === '-' || hedgeFundTrend === null ? <CardBody data-testid='hedge--'><NeutralValue>—</NeutralValue></CardBody> : null}
          {hedgeFundTrend !== '-' && hedgeFundTrend !== null
            ? <CardFooter data-testid='hedge-footer'>By <CardHeader>{roundBigNumbers(hedgeFundTrendValue)}</CardHeader> Shares Last Quarter</CardFooter>
            : null
          }
        </Card>

        <Card data-testid='insider-card'>
          <CardHeader>Insider Transactions</CardHeader>
          {insiderTrend === 'SoldShares' ? <CardBody data-testid='insider-SoldShares'><NegativeValue>Sold shares</NegativeValue></CardBody> : null}
          {insiderTrend === 'BoughtShares' ? <CardBody data-testid='insider-BoughtShares'><PositiveValue>Bought Shares</PositiveValue></CardBody> : null}
          {insiderTrend === '-' || insiderTrend === null ? <CardBody data-testid='insider--'><NeutralValue>—</NeutralValue></CardBody> : null}
          {insiderTrend !== '-' && insiderTrend !== null
            ? <CardFooter data-testid='insider-footer'>Worth <CardHeader>{last3MonthsSum}</CardHeader> over the last 3 Months</CardFooter>
            : null
          }
        </Card>

        <Card data-testid='investor-card'>
          <CardHeader>Crowd Wisdom</CardHeader>
          {investorSentiment === 'Positive' ? <CardBody data-testid='investor-Positive'><PositiveValue>{investorSentiment}</PositiveValue></CardBody> : null}
          {investorSentiment === 'VeryPositive' ? <CardBody data-testid='investor-VeryPositive'><PositiveValue>Very Positive</PositiveValue></CardBody> : null}
          {investorSentiment === 'Negative' ? <CardBody data-testid='investor-Negative'><NegativeValue>{investorSentiment}</NegativeValue></CardBody> : null}
          {investorSentiment === 'VeryNegative' ? <CardBody data-testid='investor-VeryNegative'><NegativeValue>Very Negative</NegativeValue></CardBody> : null}
          {investorSentiment === 'Neutral' ? <CardBody data-testid='investor-Neutral'><NeutralValue>{investorSentiment}</NeutralValue></CardBody> : null}
          {investorSentiment === 'Unknown' || investorSentiment === null ? <CardBody data-testid='investor-Unknown'><NeutralValue>—</NeutralValue></CardBody> : null}
          {investorSentiment !== 'Unknown' && investorSentiment !== null
            ? investorHoldingChangeLast7Days !== null && investorHoldingChangeLast7Days < 0
              ? <CardFooter data-testid='7days-negative'>Last 7 Days <CardHeader><NegativeValue>{investorLast7Days}</NegativeValue></CardHeader></CardFooter>
              : <CardFooter data-testid='7days-positive'>Last 7 Days <CardHeader><PositiveValue>{investorLast7Days}</PositiveValue></CardHeader></CardFooter>
            : null}
          {investorSentiment !== 'Unknown' && investorSentiment !== null
            ? investorHoldingChangeLast30Days !== null && investorHoldingChangeLast30Days < 0
              ? <CardFooter data-testid='30days-negative'>Last 30 Days <CardHeader><NegativeValue>{investorLast30days}</NegativeValue></CardHeader></CardFooter>
              : <CardFooter data-testid='30days-positive'>Last 30 Days <CardHeader><PositiveValue>{investorLast30days}</PositiveValue></CardHeader></CardFooter>
            : null}
        </Card>

        <Card data-testid='news-card'>
          <CardHeader>News Sentiment</CardHeader>
          {newsSentiment === 'Bullish' ? <CardBody data-testid='news-Bullish'><PositiveValue>{newsSentiment}</PositiveValue></CardBody> : null}
          {newsSentiment === 'VeryBullish' ? <CardBody data-testid='news-VeryBullish'><PositiveValue>Very Bullish</PositiveValue></CardBody> : null}
          {newsSentiment === 'Bearish' ? <CardBody data-testid='news-Bearish'><NegativeValue>{newsSentiment}</NegativeValue></CardBody> : null}
          {newsSentiment === 'VeryBearish' ? <CardBody data-testid='news-VeryBearish'><NegativeValue>Very Bearish</NegativeValue></CardBody> : null}
          {newsSentiment === 'Neutral' ? <CardBody data-testid='news-Neutral'><NeutralValue>{newsSentiment}</NeutralValue></CardBody> : null}
          {newsSentiment === 'Unknown' || newsSentiment === null ? <CardBody data-testid='news-Unknown'><NeutralValue>—</NeutralValue></CardBody> : null}
          {newsSentiment !== 'Unknown' && newsSentiment !== null
            ? <>
                <CardFooter data-testid='news-bullish-footer'>Bullish news <CardHeader>{newsBullish}</CardHeader></CardFooter>
                <CardFooter data-testid='news-bearish-footer'>Bearish news <CardHeader>{newsBearish}</CardHeader></CardFooter>
              </>
            : null}
        </Card>

        <Card data-testid='sma-card'>
          <CardAltHeader>Technicals</CardAltHeader>
          <CardHeader>SMA</CardHeader>
          <NullablePolarValue
            card={'sma'}
            polarValue={sma}
            displayValue={sma}
          />
        </Card>

        <Card data-testid='momentum-card'>
          <CardAltHeader>Technicals</CardAltHeader>
          <CardHeader>Momentum</CardHeader>
          <NullablePolarValue
            card={'momentum'}
            polarValue={technicalsTwelveMonthsMomentum}
            displayValue={technicals12MonthMomentum}
            footer='12 - Month - Charge'
          />
        </Card>

        <Card data-testid='roe-card'>
          <CardAltHeader>Fundamentals</CardAltHeader>
          <CardHeader>Return on Equity</CardHeader>
          <NullablePolarValue
            card='roe'
            polarValue={fundamentalsReturnOnEquity}
            displayValue={fundamentalsEquity}
            footer='Trailing 12-Months'
          />
        </Card>

        <Card data-testid='asset-card'>
          <CardAltHeader>Fundamentals</CardAltHeader>
          <CardHeader>Asset Growth</CardHeader>
          <NullablePolarValue
            card='asset'
            polarValue={fundamentalsAssetGrowth}
            displayValue={fundamentalsGrowth}
            footer='Trailing 12-Months' />
        </Card>
      </div>
    </>
  )
}

const Card = styled.div`
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  border-radius: 4px;
  width: 152px;
  min-width: 152px;
  height: 111px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  box-sizing: border-box;
`

const CardHeader = styled.span`
  font-size: ${({ theme }) => theme.size.heading.h5.text.value};
  line-height: ${({ theme }) => theme.size.heading.h5.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h5.weight.value};
  color: ${({ theme }) => theme.color.text.primary.value};
  margin-bottom: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CardAltHeader = styled.span`
  font-size: ${({ theme }) => theme.size.heading.h6.text.value};
  line-height: ${({ theme }) => theme.size.heading.h6.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h6.weight.value};
  color: ${({ theme }) => theme.color.text.tertiary.value};
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 2.6px;
  text-transform: uppercase;
`

const CardBody = styled.span`
  font-size: ${({ theme }) => theme.size.heading.h2.text.value};
  line-height: ${({ theme }) => theme.size.heading.h2.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h2.weight.value};
  color: ${({ theme }) => theme.color.text.long.value};
  margin-bottom: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CardFooter = styled.span`
  font-size: ${({ theme }) => theme.size.heading.h6.text.value};
  line-height: ${({ theme }) => theme.size.heading.h6.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.body.weight.value};
  color: ${({ theme }) => theme.color.text.tertiary.value};
`

const NegativeValue = styled.span`color: ${({ theme }) => theme.color.text.negativeAmount.value};`
const PositiveValue = styled.span`color: ${({ theme }) => theme.color.text.positiveAmount.value};`
const NeutralValue = styled.span`color: ${({ theme }) => theme.color.text.tertiary.value};`

const NullablePolarValue = ({
  card, polarValue, displayValue, footer
}: {
  card: string
  polarValue: string | number | null
  displayValue: string
  footer?: string
}): JSX.Element => {
  if (polarValue === null || polarValue === '—' || polarValue === 'N/A') {
    return <CardBody data-testid={`${card}-null`}><NeutralValue>—</NeutralValue></CardBody>
  }
  if (polarValue === 'Positive' || (typeof polarValue === 'number' && polarValue > 0)) {
    return <>
      <CardBody data-testid={`${card}-positive`}><PositiveValue>{displayValue}</PositiveValue></CardBody>
      { footer !== undefined ? <CardFooter data-testid={`${card}-footer`}>{footer}</CardFooter> : null}
    </>
  }
  if (polarValue === 'Negative' || (typeof polarValue === 'number' && polarValue < 0)) {
    return <>
      <CardBody data-testid={`${card}-negative`}><NegativeValue>{displayValue}</NegativeValue></CardBody>
      {footer !== undefined ? <CardFooter data-testid={`${card}-footer`}>{footer}</CardFooter> : null}
    </>
  }
  return <></>
}

const DisplayTicker = (ticker: string): string => {
  if (ticker.includes(':')) {
    return ticker.split(':')[1]
  } else {
    return ticker
  }
}

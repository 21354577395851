import styled from 'styled-components'
import { CardHeader, LoadingSpinner } from './AnalystConsensus'
import { ErrorText } from './ErrorPage'
import { Spinner } from 'ig-phoenix'
import AnalystForecastTable from './analyst-forecasts/AnalystForecastTable'
import { AnalystForecastCardProps } from './AppProps'

/**
 * Error Page component for the full view of Analyst Consensus
 * for Mini View Error Page, go to ./miniView/MiniErrorPage
 */
export default function AnalystForecastCard (props: AnalystForecastCardProps): JSX.Element {
  const { analystsData, analystsLoading, analystsError, lastPriceData, clientLocale, epic, instrumentName, trackInteraction } = props

  return <div>
    <CardHeader>DETAILED LIST OF ANALYST FORECASTS</CardHeader>
    <DetailedListCard
      data-testid='detailed-list-card'
      errorOrLoading={analystsError || analystsLoading}
    >
      { analystsError &&
        <ErrorText data-testid='stock-analysts-error-card'>
          {'We’re not able to load this data right now'} <br/> {'Please come back later'}
        </ErrorText>
      }

      { analystsLoading &&
        <LoadingSpinner data-testid='analyst-forecasts-loading'>
          <Spinner radius='40px'/>
        </LoadingSpinner>
      }
      { analystsData &&
        <AnalystForecastTable analystsData={analystsData} { ...lastPriceData } clientLocale={clientLocale} epic={epic}
          instrumentName={instrumentName} trackInteraction={trackInteraction} />
      }
    </DetailedListCard>
  </div>
}

const DetailedListCard = styled.div<{
  errorOrLoading: boolean
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-width: 260px;
  max-width: 800px;
  width: 100%;
  height: ${({ errorOrLoading }) => errorOrLoading ? '64px' : '308px'};
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  border: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  border-radius: 8px;
  margin-top: 8px;
`

import { forwardRef } from 'react'

interface HoverProps {
  isHovering: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
  children: JSX.Element
}

const HoverableCard = forwardRef((props: HoverProps, ref: React.Ref<HTMLDivElement>) => {
  const { isHovering, onMouseEnter, onMouseLeave, children } = props
  return isHovering ? <div ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} data-testid='hoverable-card'>{children}</div> : null
})

HoverableCard.displayName = 'HoverableCard'

export default HoverableCard

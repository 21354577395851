import { TipRankLogo } from '@ig-caa/media'

/**
 * Error Page component for the full view of Analyst Consensus
 * for Mini View Error Page, go to ./miniView/MiniErrorPage
 */
export default function AppHeader (): JSX.Element {
  return (
    <div className='heading'>
      <span className='heading-title'>Analyst Consensus</span>
      <span className='tipranks-logo'><TipRankLogo /></span>
      <p className='update'>Data updates every 24 hours.</p>
    </div>
  )
}

import { useTheme } from 'styled-components'
import React from 'react'

export default function ArrowUp (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.text.positiveAmount.value
  return (
    <svg width="10" height="9" viewBox="-1 0 9 9" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.0170686 3.46551C-0.0307853 3.28796 0.0242578 3.10197 0.153674 2.97888L2.83718 0.362353C2.93085 0.270958 3.05573 0.221436 3.18384 0.221436C3.31194 0.221436 3.43682 0.270958 3.53049 0.362353L6.21404 2.97892C6.34329 3.10205 6.39818 3.28797 6.35029 3.4654C6.30268 3.64183 6.16387 3.7724 5.99517 3.81571C5.8272 3.85883 5.64628 3.81167 5.52086 3.6867L3.66959 1.88166V8.87443C3.66959 9.15812 3.45197 9.3881 3.18352 9.3881C2.91506 9.3881 2.69744 9.15812 2.69744 8.87443V1.88233L0.846913 3.68664C0.721529 3.81181 0.540514 3.85912 0.372435 3.81604C0.203589 3.77276 0.0646616 3.64209 0.0170686 3.46551Z"/>
    </svg>
  )
}

export const enum Theme {
  Light = 'Light',
  Dark = 'Dark'
}
export const LIGHT_THEME = 'IGWTPRebrandLight'

export const enum ENVIRONMENTS {
  test = 'test',
  uat = 'uat',
  demo = 'demo',
  prod = 'prod'
}

export const enum TRACKING_ACTIONS {
  ANALYST_CONSENSUS_VIEW_MORE = 'Click view more Analyst Consensus',
  SMART_SCORE_VIEW_MORE = 'Click view more Smart Score'
}

export type TrackInteraction = (eventAction: TRACKING_ACTIONS, eventLabel: string, epic: string, instrumentName: string) => void

export interface AppProps {
  theme: Theme
  cst: string
  sessionData: {
    clientLocale: string
    currentAccountId: string
    liveClientId: string
    webSiteId: string
    igCompany: string
  }
  env: ENVIRONMENTS
  marketId: string
  miniView: boolean
  toggleMiniView: Function
  epic: string
  instrumentName: string
  trackInteraction: TrackInteraction
}

export interface SmartScoreProps {
  marketId: string
  env: ENVIRONMENTS
  locale: string
  cst: string
}

export interface SmartScoreData {
  ticker: string
  hedgeFundTrendValue: number | null
  smartScore: number | null
  bloggerSectorAvg: number | null
  bloggerBullishSentiment: number | null
  insidersLast3MonthsSum: number | null
  newsSentimentsBearishPercent: number | null
  newsSentimentsBullishPercent: number | null
  investorHoldingChangeLast7Days: number | null
  investorHoldingChangeLast30Days: number | null
  priceTarget: number | null
  fundamentalsReturnOnEquity: number | null
  fundamentalsAssetGrowth: number | null
  technicalsTwelveMonthsMomentum: number | null
  sma: string
  analystConsensus: string
  hedgeFundTrend: string
  insiderTrend: string
  investorSentiment: string
  newsSentiment: string
  bloggerConsensus: string
  priceTargetCurrencyCode: string
}

export interface MiniViewProps {
  toggleMiniView: Function
  marketId: string
  env: ENVIRONMENTS
  cst: string
  epic: string
  instrumentName: string
  trackInteraction: TrackInteraction
}

import { useTheme } from 'styled-components'
import React from 'react'

export default function EmptyStar (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.card.background.value
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.63348 7.47194L9.81111 11.3759C9.87448 11.5709 9.80939 11.7861 9.65019 11.9081C9.49099 12.0301 9.27431 12.0307 9.11448 11.9096L5.99354 9.45961L2.87894 11.9085C2.71872 12.0287 2.50227 12.0271 2.34363 11.9047C2.18499 11.7823 2.1206 11.567 2.18438 11.3724L3.35346 7.47781L0.191541 5.11253C0.0304628 4.99219 -0.0382653 4.77776 0.0211059 4.58077C0.0804772 4.38378 0.254537 4.24873 0.452748 4.24586H4.348L5.56346 0.329715C5.62205 0.133605 5.79632 0 5.99351 0C6.1907 0 6.36496 0.133605 6.42356 0.329715L7.63902 4.24584H11.5473C11.7456 4.24872 11.9197 4.38388 11.979 4.58097C12.0383 4.77806 11.9694 4.99253 11.8081 5.11276L8.63348 7.47194Z" fill={theme.color.separator.primary.background.value}/>
    </svg>
  )
}

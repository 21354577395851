export const enum Theme {
  Light = 'Light',
  Dark = 'Dark'
}
export const LIGHT_THEME = 'IGWTPRebrandLight'

export const enum ENVIRONMENTS {
  test = 'test',
  uat = 'uat',
  demo = 'demo',
  prod = 'prod'
}

export const enum TRACKING_ACTIONS {
  ANALYST_CONSENSUS_VIEW_MORE = 'Click view more Analyst Consensus',
  SMART_SCORE_VIEW_MORE = 'Click view more Smart Score'
}

export type TrackInteraction = (eventAction: TRACKING_ACTIONS, eventLabel: string, epic: string, instrumentName: string) => void

export interface AppProps {
  theme: Theme
  cst: string
  sessionData: {
    clientLocale: string
    currentAccountId: string
    liveClientId: string
    webSiteId: string
    igCompany: string
  }
  env: ENVIRONMENTS
  marketId: string
  tipranksMarketId: string
  epic: string
  instrumentName: string
  trackInteraction: TrackInteraction
}

export const VIEW = {
  HOME: 'HOME',
  SMART_SCORE: 'SMART_SCORE',
  ANALYST_CONSENSUS: 'ANALYST_CONSENSUS'
} as const

export type View = typeof VIEW[keyof typeof VIEW]

import '../../styles/AnalystConsensus.css'
import { AppProps } from './AppProps'
import AnalystConsensus from './AnalystConsensus'
import MiniView from './miniView/MiniView'

export function App (props: AppProps): JSX.Element {
  const { env, marketId, cst, sessionData: { clientLocale }, miniView } = props

  return (
    <div className='app-caa-analyst-consensus' data-testid='app-caa-analyst-consensus'>
      { miniView &&
        <MiniView env={env} marketId={marketId} toggleMiniView={props.toggleMiniView} cst={cst} epic={props.epic}
          instrumentName={props.instrumentName} trackInteraction={props.trackInteraction} />
      }
      { !miniView &&
        <AnalystConsensus env={env} marketId={marketId} cst={cst} clientLocale={clientLocale} epic={props.epic}
          instrumentName={props.instrumentName} trackInteraction={props.trackInteraction} />
      }
    </div>
  )
}

import '../../../styles/ACStockRating.css'
import styled, { useTheme } from 'styled-components'
import { H1, Title, gbx2DpCurrencyFormat } from '../helpers'

export default function PriceTarget ({ priceTarget, currencyCode, change, clientLocale }: { priceTarget: number, currencyCode: string, change: number, clientLocale: string }): JSX.Element {
  const theme = useTheme()

  const priceTargetAbs = Math.abs(priceTarget)
  const textColor = change > 0
    ? theme.color.text.positiveMarketMovement.value
    : change < 0
      ? theme.color.text.negativeMarketMovement.value
      : theme.color.text.tertiary.value

  return (
    <PriceTargetContainer data-testid='price-target' textColor={ textColor }>
      { priceTarget < 0 ? <BigHeader>-</BigHeader> : null}
      <H1 data-testid='price-target-currency-prefix'>
        {gbx2DpCurrencyFormat(clientLocale, currencyCode, 0).split('0', 9)[0]}
      </H1>
      <BigHeader data-testid='price-target-integer'>
        {priceTargetAbs.toFixed(2).split('.')[0] }
      </BigHeader>
      <H1 data-testid='price-target-fraction'>
        {gbx2DpCurrencyFormat(clientLocale, currencyCode, 1).includes(',') ? ',' : '.'}
        {priceTargetAbs.toFixed(2).split('.')[1] }
      </H1>
      <H1 data-testid='price-target-currency-suffix'>
        {gbx2DpCurrencyFormat(clientLocale, currencyCode, 0).split('0', 9).slice(-1)}
      </H1>
      <Title>Analyst Price target</Title>
    </PriceTargetContainer>
  )
}

const PriceTargetContainer = styled.div<{
  textColor: string
}>`
  color: ${({ textColor }) => textColor};
`

const BigHeader = styled.span`
  font-size: 36px;
  line-height: 39px;
  font-weight: bold;
`

import React from 'react'
import styled, { useTheme } from 'styled-components'
import Scale from '../score-display/Scale'
import { Circle } from '../score-display/Circle'
import '../../../styles/SSMiniView.css'
import { circleTextColor, circleBgColor } from '../score-display/Helpers'
import { MiniHeading } from '../score-display/Heading'
import { ScaleIndicator } from '../score-display/Indicator'
import { LIGHT_THEME } from '../AppProps'

export default function MiniScoreDisplay ({ score }: { score: number }): JSX.Element {
  const theme = useTheme()

  const colShort = theme.color.text.short.value
  const colLong = theme.color.text.long.value
  const colPrimary = theme.color.text.primary.value
  const colTertiary = theme.color.text.tertiary.value
  const colPrimaryInverted = theme.color.text.inverted.value
  const indicatorColor = theme.color.icons.primary.value

  const title = (score <= 3 ? 'Underperform' : (score <= 7 ? 'Neutral' : 'Outperform'))
  const titleColor = (score <= 3 ? colShort : (score <= 7 ? colTertiary : colLong))
  /* istanbul ignore next */
  const circleTextCol = theme.name === LIGHT_THEME ? colPrimaryInverted : colPrimary
  const scale = Scale(score, true, colShort, colLong, colPrimary, colTertiary, indicatorColor)

  return (
    <ScoreContainer data-testid='mini-score-container'>
      <Circle
        textColor={circleTextColor(score, colPrimary, circleTextCol)}
        bgColor={circleBgColor(score, colShort, colLong)}
        score={score}
        classname='mini-view-circle'
        animationName={`fadeScore${score}`}
      />
      <Right>
        <MiniHeading color={titleColor} data-testid='heading'>{title}</MiniHeading>
        <ScaleContainer>{scale}</ScaleContainer>
        <ScaleIndicator className='scale-indicator-bottom' size='mini' animationName={`slideTo${score}`} loadAnimation={true} />
      </Right>
    </ScoreContainer>
  )
}

const ScoreContainer = styled.div`
  width: 269px;
  height: 64px;
  display: flex;
  flex-wrap: wrap;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ScaleContainer = styled.div`
  display: flex;
  column-gap: 1px;
`

import React from 'react'
import { useTheme } from 'styled-components'

export default function NoAnalystConsensus(): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.icons.disabled.value

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_393_9395" fill="white">
        <path d="M12 0C14.1043 2.50936e-08 16.1716 0.553355 17.9946 1.60455C19.8175 2.65575 21.332 4.16784 22.386 5.98912C23.4401 7.8104 23.9967 9.87684 24 11.9811C24.0033 14.0855 23.4532 16.1536 22.4049 17.9782L17.2024 14.9891C17.7266 14.0768 18.0016 13.0427 18 11.9906C17.9983 10.9384 17.72 9.9052 17.193 8.99456C16.666 8.08392 15.9087 7.32788 14.9973 6.80228C14.0858 6.27668 13.0522 6 12 6V0Z" />
      </mask>
      <path d="M12 0C14.1043 2.50936e-08 16.1716 0.553355 17.9946 1.60455C19.8175 2.65575 21.332 4.16784 22.386 5.98912C23.4401 7.8104 23.9967 9.87684 24 11.9811C24.0033 14.0855 23.4532 16.1536 22.4049 17.9782L17.2024 14.9891C17.7266 14.0768 18.0016 13.0427 18 11.9906C17.9983 10.9384 17.72 9.9052 17.193 8.99456C16.666 8.08392 15.9087 7.32788 14.9973 6.80228C14.0858 6.27668 13.0522 6 12 6V0Z" fill="#B5BDBE" stroke="white" strokeWidth="2" mask="url(#path-1-inside-1_393_9395)" />
      <mask id="path-2-inside-2_393_9395" fill="white">
        <path d="M22.3923 18C21.3402 19.8224 19.8273 21.3361 18.0054 22.3892C16.1836 23.4423 14.1169 23.9978 12.0126 24C9.90826 24.0022 7.84037 23.451 6.01633 22.4017C4.1923 21.3524 2.67625 19.8419 1.62028 18.0218L6.81014 15.0109C7.33813 15.921 8.09615 16.6762 9.00817 17.2009C9.92018 17.7255 10.9541 18.0011 12.0063 18C13.0584 17.9989 14.0918 17.7211 15.0027 17.1946C15.9136 16.668 16.6701 15.9112 17.1962 15L22.3923 18Z" />
      </mask>
      <path d="M22.3923 18C21.3402 19.8224 19.8273 21.3361 18.0054 22.3892C16.1836 23.4423 14.1169 23.9978 12.0126 24C9.90826 24.0022 7.84037 23.451 6.01633 22.4017C4.1923 21.3524 2.67625 19.8419 1.62028 18.0218L6.81014 15.0109C7.33813 15.921 8.09615 16.6762 9.00817 17.2009C9.92018 17.7255 10.9541 18.0011 12.0063 18C13.0584 17.9989 14.0918 17.7211 15.0027 17.1946C15.9136 16.668 16.6701 15.9112 17.1962 15L22.3923 18Z" fill="#B5BDBE" stroke="white" strokeWidth="2" mask="url(#path-2-inside-2_393_9395)" />
      <mask id="path-3-inside-3_393_9395" fill="white">
        <path d="M1.6077 18C0.555541 16.1776 0.00110346 14.1106 1.64494e-06 12.0063C-0.00110017 9.90197 0.551172 7.83437 1.60142 6.01089C2.65166 4.1874 4.16295 2.67215 5.98368 1.61713C7.80441 0.562116 9.87056 0.00443328 11.9749 2.63155e-05L11.9874 6.00001C10.9353 6.00222 9.90221 6.28106 8.99184 6.80857C8.08148 7.33607 7.32583 8.0937 6.80071 9.00544C6.27559 9.91719 5.99945 10.951 6 12.0031C6.00055 13.0553 6.27777 14.0888 6.80385 15L1.6077 18Z" />
      </mask>
      <path d="M1.6077 18C0.555541 16.1776 0.00110346 14.1106 1.64494e-06 12.0063C-0.00110017 9.90197 0.551172 7.83437 1.60142 6.01089C2.65166 4.1874 4.16295 2.67215 5.98368 1.61713C7.80441 0.562116 9.87056 0.00443328 11.9749 2.63155e-05L11.9874 6.00001C10.9353 6.00222 9.90221 6.28106 8.99184 6.80857C8.08148 7.33607 7.32583 8.0937 6.80071 9.00544C6.27559 9.91719 5.99945 10.951 6 12.0031C6.00055 13.0553 6.27777 14.0888 6.80385 15L1.6077 18Z" fill="#B5BDBE" stroke="white" strokeWidth="2" mask="url(#path-3-inside-3_393_9395)" />
    </svg>
  )
}
import '../../styles/SmartScore.css'
import { AppProps } from './AppProps'
import SmartScore from './SmartScore'
import MiniView from './mini-view/MiniView'

export function App (props: AppProps): JSX.Element {
  const { env, marketId, cst, sessionData: { clientLocale }, miniView } = props

  return (
    <div className='app-caa-smart-score' data-testid='app-caa-smart-score'>
      { miniView &&
        <MiniView env={env} marketId={marketId} toggleMiniView={props.toggleMiniView} cst={cst} epic={props.epic}
          instrumentName={props.instrumentName} trackInteraction={props.trackInteraction} />
      }
      { !miniView &&
        <SmartScore env={env} marketId={marketId} locale={clientLocale} cst={cst} />
      }
    </div>
  )
}

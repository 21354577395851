import '../../../styles/ACStockRating.css'
import { Chart, ArcElement, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import styled, { useTheme } from 'styled-components'
import { StockOverviewData } from '../AppProps'

Chart.register(ArcElement, Legend)

export default function RatingsDoughnut ({ stockData }: { stockData: StockOverviewData }): JSX.Element {
  const theme = useTheme()

  const doughnutOptions = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    cutout: '70%',
    radius: 48
  }

  const doughnutData = {
    labels: ['Buy', 'Hold', 'Sell'],
    datasets: [{
      data: [stockData.buy, stockData.hold, stockData.sell],
      backgroundColor: [theme.color.text.long.value, theme.color.toggle.disabled.background.value, theme.color.text.short.value],
      borderColor: theme.color.background.primarySurface.value
    }]
  }

  return (
    <div className='doughnut-canvas'>
      <Doughnut data-testid='doughnut-chart' data={doughnutData} options={doughnutOptions} />
      <div className='doughnut-text'>
        <Header>{stockData.totalAnalysts}</Header>
        <DoughnutText>Ratings</DoughnutText>
      </div>
    </div>
  )
}

const Header = styled.h5`
  font-size: ${({ theme }) => theme.size.heading.h1.text.value};
  line-height: ${({ theme }) => theme.size.heading.h1.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
  color: ${({ theme }) => theme.color.text.primary.value};
  margin: 0;
`

const DoughnutText = styled.p`
  font-size: ${({ theme }) => theme.size.heading.h5.text.value};
  line-height: ${({ theme }) => theme.size.heading.h5.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h5.weight.value};
  color: ${({ theme }) => theme.color.text.primary.value};
  margin: 0;
`

import styled, { useTheme } from 'styled-components'
import { AnalystProfileData, AnalystProfileProps } from '../AppProps'
import { NoProfilePicture } from '@ig-caa/media'
import StarsRating from './StarsRating'
import { Doughnut, Bar } from 'react-chartjs-2'
import { Chart, ArcElement, Legend, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js'
import { useQuery } from '@tanstack/react-query'
import fetchAnalystProfile from '../../../utils/fetchAnalystProfile'
import { Spinner } from '@ig-phoenix/spinner'

Chart.register(ArcElement, Legend, CategoryScale, LinearScale, BarElement, Title, Tooltip)

export default function AnalystProfile (props: AnalystProfileProps): JSX.Element {
  const theme = useTheme()

  const { env, expertUID, cst } = props

  const { isLoading, isError, data } = useQuery<AnalystProfileData, any>({
    queryKey: ['analyst-profile', expertUID],
    queryFn: async () => await fetchAnalystProfile(env, expertUID, cst),
    refetchOnWindowFocus: false
  })

  if (isLoading) {
    return (
      <AnalystProfileCard style={{ alignItems: 'center' }}>
        <Spinner radius='40px' />
      </AnalystProfileCard>
    )
  }

  if (isError || !data) {
    return (
      <AnalystProfileCard data-testid='profile-error-card'>
        <ErrorText>
          {'We’re not able to load this data right now'} <br/> {'Please come back later'}
        </ErrorText>
      </AnalystProfileCard>
    )
  }

  const doughnutOptions = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    cutout: '70%'
  }

  const successRateColor = data.successRate > 0.5
    ? theme.color.text.positiveAmount.value
    : theme.color.text.negativeAmount.value

  const excessReturnColor = data.excessReturn > 0
    ? theme.color.text.positiveAmount.value
    : theme.color.text.negativeAmount.value

  const doughnutData = {
    datasets: [{
      data: [data.goodRecommendations, data.totalRecommendations - data.goodRecommendations],
      backgroundColor: [successRateColor, theme.color.button.disabled.background.value],
      borderColor: theme.color.background.primarySurface.value
    }]
  }

  const barOptions = {
    indexAxis: 'y' as const,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        display: false,
        grid: {
          display: false
        }
      },
      y: {
        stacked: true,
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  }

  const buyPercent = data.ratingDistribution.buyPercent
  const holdPercent = buyPercent + data.ratingDistribution.holdPercent > 1 ? 1 - buyPercent : data.ratingDistribution.holdPercent
  const sellPercent = buyPercent + holdPercent + data.ratingDistribution.sellPercent === 0
    ? 0
    : buyPercent + holdPercent === 1 ? 0.0 : 1 - (data.ratingDistribution.buyPercent + data.ratingDistribution.holdPercent)

  const barData = {
    labels: [''],
    datasets: [{
      label: 'Buy',
      data: [buyPercent],
      backgroundColor: theme.color.text.positiveAmount.value,
      barThickness: 4
    }, {
      label: 'Hold',
      data: [holdPercent],
      backgroundColor: theme.color.button.disabled.background.value,
      barThickness: 4
    }, {
      label: 'Sell',
      data: [sellPercent],
      backgroundColor: theme.color.text.negativeAmount.value,
      barThickness: 4
    }]
  }

  return (
    <AnalystProfileCard data-testid='analyst-profile'>
      <Header>
        {data.expertPictureUrl === ''
          ? <div data-testid='no-profile-picture'><NoProfilePicture/></div>
          : <ProfilePicture data-testid='profile-image' src={data.expertPictureUrl}/>}
        <ProfileDetailsContainer>
          <Name>{ data.analystName }</Name>
          <Company>{ data.firmName }</Company>
          <StarsRating rating={ data.numOfStars } />
        </ProfileDetailsContainer>
      </Header>
      <SuccessRateContainer>
        <SuccessRateDiv>
          <SuccessRate>Success Rate</SuccessRate>
          <SuccessRateDescr data-testid={`success-rate-descr-${data.goodRecommendations}-${data.totalRecommendations}`}>
            {data.goodRecommendations} out of {data.totalRecommendations}<br/>ratings were successful
          </SuccessRateDescr>
        </SuccessRateDiv>
        <DoughnutCanvas>
          <Doughnut data-testid="doughnut-success-rate" data={ doughnutData } options={ doughnutOptions } />
          <DoughnutText color={ successRateColor }>
            { Math.round(data.successRate * 100) }%
          </DoughnutText>
        </DoughnutCanvas>
      </SuccessRateContainer>
      <AverageReturnContainer>
        <AverageReturnDiv>
          <AverageReturn>Average Return</AverageReturn>
          <AverageReturnDescr>Average Return per rating</AverageReturnDescr>
        </AverageReturnDiv>
        <ExcessReturn data-testid='excess-return' color={ excessReturnColor }>
          { data.excessReturn > 0 ? '+' : '' }
          { (data.excessReturn * 100).toFixed(2) }%
        </ExcessReturn>
      </AverageReturnContainer>
      <MainSectorContainer>
        <MainSector>Main Sector</MainSector>
        <MainSectorValue data-testid='main-sector-value'>
          { data.mainSector === 'Unknown' ? '-' : data.mainSector }
        </MainSectorValue>
      </MainSectorContainer>
      <GeoCoverageContainer>
        <GeoCoverage>Geo Coverage</GeoCoverage>
        <GeoCoverageValue data-testid='geo-coverage-value'>
          { data.ratingDistribution.formattedCountries === 'Unknown' ? '-' : data.ratingDistribution.formattedCountries}
        </GeoCoverageValue>
      </GeoCoverageContainer>
      <DistributionContainer>
        <Distribution data-testid='distribution'>Distribution</Distribution>
        <DistributionLineChart>
          <DistributionLineChartLabels>
            <ColouredPercent color={theme.color.text.positiveAmount.value}>
              Buy
              {' '}
              { (data.ratingDistribution.buyPercent * 100).toFixed(1) }%
            </ColouredPercent>
            <ColouredPercent color={theme.color.text.tertiary.value}>
              Hold
              {' '}
              { (holdPercent * 100).toFixed(1) }%
            </ColouredPercent>
            <ColouredPercent color={theme.color.text.negativeAmount.value}>
              Sell
              {' '}
              { (sellPercent * 100).toFixed(1) }%
            </ColouredPercent>
          </DistributionLineChartLabels>
          <BarContainer>
            { buyPercent + holdPercent + sellPercent === 0 ? <div data-testid="no-bar"></div> : <Bar data-testid="bar" options={barOptions} data={barData} height="24" width="268"/> }
          </BarContainer>
        </DistributionLineChart>
      </DistributionContainer>
    </AnalystProfileCard>
  )
}

const AnalystProfileCard = styled.div`
  width: 268px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.background.primarySurface.value};
  border: 1px solid ${({ theme }) => theme.color.separator.primary.background.value};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(38, 38, 41, 0.16);
  gap: 16px;
  padding: 24px;
  box-sizing: content-box;
`

const ProfilePicture = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: 100% 100%;
`

const Header = styled.div`
  display: flex;
  height: 80px;
`

const ProfileDetailsContainer = styled.div`
  margin-left: 16px;
  align-self: center;
`

const Name = styled.div`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-size: ${({ theme }) => theme.size.heading.h2.text.value};
  line-height: ${({ theme }) => theme.size.heading.h2.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h2.weight.value};
`

const Company = styled.div`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-size: 15px;
  line-height: 22px;
  font-weight: normal;
`

const SuccessRateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
`

const SuccessRateDiv = styled.div`
  align-self: center;
`

const SuccessRate = styled.div`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-size: ${({ theme }) => theme.size.heading.h3.text.value};
  line-height: ${({ theme }) => theme.size.heading.h3.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h3.weight.value};
  margin-bottom: 8px;
`

const SuccessRateDescr = styled.div`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
`

const DoughnutCanvas = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-content: start;
  position: relative;
`

const DoughnutText = styled.div<{
  color: string
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${({ color }) => color};
  font-size: ${({ theme }) => theme.size.heading.h2.text.value};
  line-height: ${({ theme }) => theme.size.heading.h2.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h2.weight.value};
`

const AverageReturnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 44px;
`

const AverageReturnDiv = styled.div`
  align-self: center;
`

const AverageReturn = styled.div`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-size: ${({ theme }) => theme.size.heading.h3.text.value};
  line-height: ${({ theme }) => theme.size.heading.h3.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h3.weight.value};
  margin-bottom: 8px;
`

const AverageReturnDescr = styled.div`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
`

const ExcessReturn = styled.div<{
  color: string
}>`
  background: ${({ color }) => color};
  color: ${({ theme }) => theme.color.text.inverted.value};
  align-self: center;
  height: 35px;
  width: 77px;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  line-height: 35px;
  font-weight: 600;
  text-align: center;
`

const ErrorText = styled.div`
  font-size: ${({ theme }) => theme.size.body.small.text.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  text-align: center;
`
const MainSectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 20px;
`

const MainSector = styled.div`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-size: ${({ theme }) => theme.size.heading.h3.text.value};
  line-height: ${({ theme }) => theme.size.heading.h3.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h3.weight.value};
  align-self: center;
  margin-right: 10px;
`

const MainSectorValue = styled.div`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  align-self: center;
`

const GeoCoverageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 20px;
`

const GeoCoverage = styled.div`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-size: ${({ theme }) => theme.size.heading.h3.text.value};
  line-height: ${({ theme }) => theme.size.heading.h3.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h3.weight.value};
  min-width: 100px;
`

const GeoCoverageValue = styled.div`
  color: ${({ theme }) => theme.color.text.tertiary.value};
  font-size: 13px;
  font-weight: 400;
  float: right;
  text-align: right;
`

const DistributionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px
`

const Distribution = styled.div`
  color: ${({ theme }) => theme.color.text.primary.value};
  font-size: ${({ theme }) => theme.size.heading.h3.text.value};
  line-height: ${({ theme }) => theme.size.heading.h3.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h3.weight.value};
  align-self: flex-start;
`

const DistributionLineChart = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -16px;
`

const DistributionLineChartLabels = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  margin: -1px 0px -9px 16px;
`

const ColouredPercent = styled.div<{
  color: string
}>`
  color: ${({ color }) => color};
`

const BarContainer = styled.div`
  margin-left: 2px;
`

// follow button out of scope for first drop, will be needed eventually

// const Button = styled.button`
//   background: ${({ theme }) => theme.color.button.secondary.background.value};
//   color: ${({ theme }) => theme.color.text.primary.value};
//   height: 28px;
//   width: 132px;
//   border-color: ${({ theme }) => theme.color.button.secondary.border.value};
//   border-radius: 4px;
//   box-shadow: 0px 2px 2px 0px #26262914;
//   border-width: 1px;
//   border-style: solid;
//   font-size: 13px;
//   line-height: 13px;
//   font-weight: 500;
//   text-align: center;
//   align-self: center;
// `

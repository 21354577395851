import '../../styles/AnalystConsensus.css'
import styled from 'styled-components'
import { AnalystConsensusProps, StockAnalystData, StockOverviewData } from './AppProps'
import { useTranslation } from '@ig-caa/i18n'
import { useQuery } from '@tanstack/react-query'
import fetchStockOverview from '../../utils/fetchStockOverview'
import { Spinner, Link } from 'ig-phoenix'
import { validateAnalystConsensusData, validateStockAnalystData } from '../../utils/validationHelpers'
import { ERROR_TYPE } from '../../utils/getErrorType'
import ErrorPage from './ErrorPage'
import fetchStockAnalysts from '../../utils/fetchStockAnalysts'
import AppHeader from './AppHeader'
import StockRatingCard from './StockRatingCard'
import AnalystForecastCard from './AnalystForecastCard'

export default function AnalystConsensus (props: AnalystConsensusProps): JSX.Element {
  const { t } = useTranslation('translation')
  const { marketId, env, cst, clientLocale, epic, instrumentName, trackInteraction } = props

  const { isLoading: overviewLoading, isError: isOverviewError, error: overviewError, data: overviewData } = useQuery<StockOverviewData, any>({
    queryKey: ['stockOverview', marketId],
    queryFn: async () => await fetchStockOverview(env, marketId, cst).then(validateAnalystConsensusData),
    refetchOnWindowFocus: false
  })

  const { isLoading: analystsLoading, isError: isAnalystError, error: analystError, data: analystsData } = useQuery<StockAnalystData[], any>({
    queryKey: ['stock-analysts', marketId],
    queryFn: async () => await fetchStockAnalysts(env, marketId, cst).then(validateStockAnalystData),
    refetchOnWindowFocus: false
  })

  if ((overviewLoading && analystsLoading) ||
    (isOverviewError && analystsLoading) ||
    (overviewLoading && isAnalystError)) {
    return <div className='analyst-consensus-loading' data-testid='isLoading'><Spinner radius='40px' /></div>
  }

  const canDisplayAnyContent = !(isOverviewError && isAnalystError)
  const analystsForecastHasData = analystError?.message !== ERROR_TYPE.EMPTY_DATA
  const lastPrice = {
    lastPrice: overviewData ? overviewData.lastPrice : undefined,
    env,
    cst
  }

  return (
    <>
      <AppContainer className='analyst-consensus' data-testid='analyst-consensus_full-view'>
        <div className='analyst-consensus-container'>
          <AppHeader />

          { !canDisplayAnyContent &&
            <div data-testid='loading-error-middle-screen'><ErrorPage pageType={ERROR_TYPE.DEFAULT} /></div>
          }

          { canDisplayAnyContent && <>
            <StockRatingCard
              overviewData={overviewData}
              overviewLoading={overviewLoading}
              clientLocale={clientLocale}
              isOverviewError={isOverviewError}
              overviewError={overviewError}
            />

            { analystsForecastHasData &&
              <AnalystForecastCard
                analystsData={analystsData}
                analystsLoading={analystsLoading}
                clientLocale={clientLocale}
                isAnalystError={isAnalystError}
                analystsError={analystError}
                lastPriceData={lastPrice}
                epic={epic}
                instrumentName={instrumentName}
                trackInteraction={trackInteraction}
              />
            }
          </>}
        </div>
        <Disclaimer data-testid='disclaimer-learn-more'>
          <Link href={`${t('disclaimer-link')}${t('disclaimer-link-suffix')}`} target='_blank' data-testid='disclaimer-learn-more-link'>
            Learn more
          </Link>
          {' '}
          about Analyst Consensus.
        </Disclaimer>
        <Disclaimer data-testid='disclaimer'>
          {t('disclaimer')}
        </Disclaimer>
      </AppContainer>
    </>
  )
}

export const LoadingSpinner = styled.div`
  position: relative;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  width: 40px;
`

const AppContainer = styled.div`
  background-color: ${({ theme }) => theme.color.background.secondarySurface.value};
  color: ${({ theme }) => theme.color.text.primary.value};
`

export const CardHeader = styled.span`
  font-size: ${({ theme }) => theme.size.heading.h6.text.value};
  line-height: 14px;
  font-weight: ${({ theme }) => theme.font.body.weight.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  letter-spacing: 2px;
`

const Disclaimer = styled.div`
  font-size: ${({ theme }) => theme.size.caption.medium.text.value};
  line-height: ${({ theme }) => theme.size.caption.medium.lineHeight.value};
  color: ${({ theme }) => theme.color.text.secondary.value};
  margin-bottom: 4px;
  max-width: 800px;
`

import { useTheme } from 'styled-components'
import React from 'react'

export default function NoSmartScore (): JSX.Element {
  const theme = useTheme()
  const svgColour = theme.color.icons.disabled.value
  return (
    <svg width="48" height="24" viewBox="0 0 48 24" fill={svgColour} xmlns="http://www.w3.org/2000/svg">
      <rect y="8" width="6" height="4" />
      <rect x="7" y="8" width="6" height="4" />
      <rect x="14" y="8" width="6" height="4" />
      <rect x="21" y="8" width="6" height="4" />
      <rect x="28" y="8" width="6" height="4" />
      <rect x="35" y="8" width="6" height="4" />
      <rect x="42" y="8" width="6" height="4" />
      <path d="M24 13L27 16H21L24 13Z" />
    </svg>
  )
}

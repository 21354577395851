import { FC, PropsWithChildren, useState } from 'react'
import { AppProps, VIEW, View } from './AppProps'
import Home from './Home'
import styled from 'styled-components'
import { Link } from 'ig-phoenix'
import { App as SmartScoreApp } from './smart-score/App'
import { App as AnalystConsensusApp } from './analyst-consensus/App'
import { ArrowLeft } from '@ig-caa/media'

const ExpertAnalysis: FC<AppProps> = (props) => {
  const [view, setView] = useState<View>(VIEW.HOME)

  switch (view) {
    case VIEW.HOME:
      return <Home {...props} setView={setView} />
    case VIEW.SMART_SCORE:
      return (
        <TopNav setView={setView}>
          <SmartScoreApp
            {...props}
            toggleMiniView={/* istanbul ignore next */ () => {}}
            trackInteraction={props.trackInteraction as any}
            miniView={false}
            env={props.env as any}
            theme={props.theme as any}
            sessionData={props.sessionData as any}
            marketId={props.tipranksMarketId} />
        </TopNav>
      )
    case VIEW.ANALYST_CONSENSUS:
      return (
        <TopNav setView={setView}>
          <AnalystConsensusApp
            {...props}
            toggleMiniView={/* istanbul ignore next */ () => {}}
            trackInteraction={props.trackInteraction as any}
            miniView={false}
            env={props.env as any}
            theme={props.theme as any}
            sessionData={props.sessionData as any}
            marketId={props.tipranksMarketId} />
        </TopNav>
      )
  }
}

export default ExpertAnalysis

const TopNav: FC<PropsWithChildren<{ setView: (view: View) => void }>> = ({ children, setView }) => {
  return (
    <FullAppView>
      <BackButton data-testid='back-to-expert-insights'>
        <Link onClick={() => setView(VIEW.HOME)}>
          <LinkSpacing>
            <ArrowLeft /> <span>Back to TipRanks</span>
          </LinkSpacing>
        </Link>
      </BackButton>
      <ReducedPadding>
        {children}
      </ReducedPadding>
    </FullAppView>
  )
}

const FullAppView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ReducedPadding = styled.div`
  margin: -16px;
`

const LinkSpacing = styled.div`
  display: flex;
  align-items: center;
`

const BackButton = styled.div`
  height: 34px;
`

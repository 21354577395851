import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import '../styles/App.css'
import { AppProps, TrackInteraction } from './AppProps'
import { Providers } from '@ig-caa/mfe-infrastructure'
import ExpertAnalysis from './ExpertAnalysis'

/* istanbul ignore next */
const DEFAULT_TRACKING: TrackInteraction = (
  eventAction,
  eventLabel,
  epic,
  instrumentName) => {
  console.log('tracked:', eventAction, eventLabel, epic, instrumentName)
}

export function App ({ trackInteraction = DEFAULT_TRACKING, epic = '', instrumentName = '', ...props }: AppProps): JSX.Element {
  const { theme, sessionData: { clientLocale, webSiteId, igCompany } } = props
  const localeLoader = async (locale: string) => await import(`../locales/${locale}.json`)
  const supportedLanguages = ['en-GB', 'de-DE', 'fr-FR', 'it-IT', 'nl-NL', 'no-NO', 'pt-PT', 'sv-SE', 'es-ES', 'en-ZA', 'zh-CN', 'zh-TW']

  return (
    <Providers
      appName='expert-analysis'
      clientLocale={clientLocale}
      igCompany={igCompany}
      localeLoader={localeLoader}
      supportedLanguages={supportedLanguages}
      theme={theme}
      webSiteId={webSiteId}
    >
      <div className={`app-caa-expert-analysis ${theme}`} data-testid='app-caa-expert-analysis'>
        <ExpertAnalysis trackInteraction={DEFAULT_TRACKING} epic='' instrumentName='' {...props} />
      </div>
    </Providers>
  )
}

const lifecycles = singleSpaReact({ React, ReactDOM, rootComponent: App })
export const { bootstrap, mount, unmount, update } = lifecycles
export default lifecycles

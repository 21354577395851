import styled from 'styled-components'

export const MiniHeading = styled.div`
  font-size: ${({ theme }) => theme.size.heading.h1.text.value};
  line-height: ${({ theme }) => theme.size.heading.h1.lineHeight.value};
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
  color: ${props => props.color};
  margin-bottom: 8px;
  height:100%;
`

export const MainHeading = styled.div`
  font-weight: ${({ theme }) => theme.font.heading.h1.weight.value};
  color: ${props => props.color};
  height:100%;
`
